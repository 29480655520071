import React from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import PropTypes from 'prop-types';

const WhiteMainButton = (props) => {
  const {
    text, buttonWidth, buttonHeight, onClick, icon, fontSize, margin, verticalAlign,
    htmlType,
  } = props;
  return (
    <Button
      type="primary"
      htmlType={htmlType}
      onClick={onClick}
      className={margin && 'margin-top-xl'}
      style={{
        float: 'right',
        fontSize,
        margin: '40px',
        border: '0px',
        borderRadius: '10px',
        width: buttonWidth,
        height: buttonHeight,
        background: 'white',
        color: 'black',
      }}
    >
      {
        icon ? (
          <Icon
            style={{ float: 'left' }}
            component={() => (
              <img
                style={{ verticalAlign }}
                src={icon}
                alt="icon"
              />
            )}
          />
        ) : ''
      }
      {text}
    </Button>
  );
};

WhiteMainButton.defaultProps = {
  onClick: () => {},
  buttonWidth: '200px',
  buttonHeight: '70px',
  icon: '',
  fontSize: '18px',
  margin: true,
  verticalAlign: '-webkit-baseline-middle',
  htmlType: '',
};

WhiteMainButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  icon: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.bool,
  verticalAlign: PropTypes.string,
  htmlType: PropTypes.string,
};

const MainButton = (props) => {
  const {
    text, buttonWidth, buttonHeight, onClick, icon, fontSize, margin, verticalAlign,
    htmlType, float, marginRight, marginLeft, loading,
  } = props;
  return (
    <Button
      type="primary"
      htmlType={htmlType}
      onClick={onClick}
      className={margin && 'margin-top-xl'}
      loading={loading}
      style={{
        float,
        fontSize,
        color: 'white',
        fontWeight: 'bold',
        backgroundColor: '#5663F0',
        border: '0px',
        borderRadius: '10px',
        minWidth: buttonWidth,
        height: buttonHeight,
        marginRight: marginRight && '1em',
        marginLeft: marginLeft && '1em',
      }}
    >
      {
        icon ? (
          <Icon
            style={{ float: 'left' }}
            component={() => (
              <img
                style={{ verticalAlign }}
                src={icon}
                alt="icon"
              />
            )}
          />
        ) : ''
      }
      {text}
    </Button>
  );
};

MainButton.defaultProps = {
  onClick: () => {},
  buttonWidth: '200px',
  buttonHeight: '70px',
  icon: '',
  fontSize: '18px',
  margin: false,
  float: 'right',
  verticalAlign: '-webkit-baseline-middle',
  htmlType: '',
  marginRight: false,
  marginLeft: false,
  loading: false,
};

MainButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  icon: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.bool,
  float: PropTypes.string,
  verticalAlign: PropTypes.string,
  htmlType: PropTypes.string,
  marginRight: PropTypes.bool,
  marginLeft: PropTypes.bool,
  loading: PropTypes.bool,
};

const TransparentButton = (props) => {
  const {
    text, buttonWidth, buttonHeight, onClick, icon, fontSize,
    margin, verticalAlign, marginRight, marginLeft,
    htmlType,
  } = props;
  return (
    <Button
      type="primary"
      htmlType={htmlType}
      onClick={onClick}
      className={margin && 'margin-top-xl'}
      style={{
        float: 'right',
        fontSize,
        color: '#5663F0',
        fontWeight: 'bold',
        backgroundColor: 'transparent',
        border: '1px solid #5663F0',
        borderRadius: '10px',
        minWidth: buttonWidth,
        height: buttonHeight,
        marginRight: marginRight && '1em',
        marginLeft: marginLeft && '1em',
      }}
    >
      {
        icon ? (
          <Icon
            style={{ float: 'left' }}
            component={() => (
              <img
                style={{ verticalAlign }}
                src={icon}
                alt="icon"
              />
            )}
          />
        ) : ''
      }
      {text}
    </Button>
  );
};

TransparentButton.defaultProps = {
  onClick: () => {},
  buttonWidth: '200px',
  buttonHeight: '70px',
  icon: '',
  fontSize: '18px',
  margin: false,
  verticalAlign: '-webkit-baseline-middle',
  htmlType: '',
  marginRight: false,
  marginLeft: false,
};

TransparentButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  icon: PropTypes.string,
  fontSize: PropTypes.string,
  margin: PropTypes.bool,
  verticalAlign: PropTypes.string,
  htmlType: PropTypes.string,
  marginRight: PropTypes.bool,
  marginLeft: PropTypes.bool,
};

export { WhiteMainButton, MainButton, TransparentButton };
