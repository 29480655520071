/* eslint-disable react/button-has-type,react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { Row } from 'reactstrap';
import Icon from '@ant-design/icons';
import coursesIcon from '../../assets/Images/coursesIcon.svg';
import usersIcon from '../../assets/Images/userIcon.svg';
import subscriptionIcon from '../../assets/Images/subscriptionIcon.svg';
import instructionsIcon from '../../assets/Images/instructionsIcon.svg';
import notificationsIcon from '../../assets/Images/notificationsIcon.svg';
import settingsIcon from '../../assets/Images/settings.svg';
import logo from '../../assets/Images/logo.png';

const { Sider } = Layout;

function Sidebar(props) {
  const { location } = props;
  const [currentSelection, setCurrentSelection] = useState(['/main/courses']);

  useEffect(() => {
    setCurrentSelection([location.pathname === '/main' ? '/main/courses' : location.pathname]);
  }, [location]);

  return (
    <div>
      <Sider
        className="siderStyling"
        width="100px"
        trigger={null}
      >
        {/* icon on sidebar */}
        <Row style={{ height: '13%' }}>
          <Link to="/main/courses">
            <Icon component={() => (<img src={logo} style={{ width: '60%', padding: '30px 0px' }} alt="logo" />)} />
          </Link>
        </Row>
        <Menu
          className="sidebar-menu margin-top-sm"
          mode="inline"
          style={{ height: '100vh', listStyle: 'none', border: '0' }}
          onClick={(e) => setCurrentSelection(e.key)}
          selectedKeys={currentSelection}
        >
          <Menu.Item key="/main/courses" style={{ marginTop: '30px' }}>
            <Link to="/main/courses" href="/main/courses">
              <Icon component={() => (<img src={coursesIcon} style={{ marginBottom: '5px' }} alt="courses" />)} />
            </Link>
          </Menu.Item>
          <Menu.Item key="/main/users" style={{ marginTop: '30px' }}>
            <Link to="/main/users" href="/main/users">
              <Icon component={() => (<img src={usersIcon} style={{ marginBottom: '5px' }} alt="users" />)} />
            </Link>
          </Menu.Item>
          <Menu.Item key="/main/subscriptions" style={{ marginTop: '30px' }}>
            <Link to="/main/subscriptions" href="/main/subscriptions">
              <Icon component={() => (<img src={subscriptionIcon} style={{ marginBottom: '5px' }} alt="subscription" />)} />
            </Link>
          </Menu.Item>
          <Menu.Item key="/main/notifications" style={{ marginTop: '20px' }}>
            <Link to="/main/notifications" href="/main/notifications">
              <Icon component={() => (<img src={notificationsIcon} style={{ marginBottom: '5px' }} alt="notifications" />)} />
            </Link>
          </Menu.Item>
          <Menu.Item key="/main/instructions" style={{ marginTop: '30px' }}>
            <Link to="/main/instructions" href="/main/instructions">
              <Icon component={() => (<img src={instructionsIcon} style={{ marginBottom: '5px' }} alt="instructions" />)} />
            </Link>
          </Menu.Item>
          <Menu.Item key="/main/settings" style={{ marginTop: '30px' }}>
            <Link to="/main/settings" href="/main/settings">
              <Icon component={() => (<img src={settingsIcon} style={{ marginBottom: '5px' }} alt="settings" />)} />
            </Link>
          </Menu.Item>
        </Menu>
      </Sider>
    </div>
  );
}

export default withRouter(Sidebar);
