/* eslint-disable react/prop-types,import/prefer-default-export */
/* eslint-disable import/no-unresolved */
import React from 'react';
import { Row, Col } from 'reactstrap';
import authImage from '../../assets/Images/authImage.svg';

function AuthLayout(props) {
  const {
    form, title, extra, marginTop,
  } = props;

  return (
    <div
      style={{
        height: '100vh',
        background: `url(${authImage}) no-repeat 50% 50% fixed`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      <Row>
        <Col sm={6} />
        <Col sm={6}>
          <div style={{
            height: '100vh',
            background: 'white',
            padding: '100px',
            paddingTop: marginTop,
          }}
          >
            <div style={{ fontSize: '35px', fontWeight: '600' }}>
              {title}
            </div>
            {
              extra
              && (
              <>
                <br />
                <div style={{
                  color: '#767676', fontWeight: '400', fontSize: '24px', width: '70%',
                }}
                >
                  {extra}
                </div>
              </>
              )
            }
            <br />
            <br />
            {form}
          </div>
        </Col>
      </Row>
    </div>
  );
}

AuthLayout.defaultProps = {
  marginTop: '25%',
};

export { AuthLayout };
