/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import {
  withRouter, Route, useHistory, Redirect,
} from 'react-router-dom';
import { Layout } from 'antd';
import { useQuery } from '@apollo/client';
import Sidebar from '../components/sidebar';
import {
  Users, Subscriptions, PlanForm, Instructions,
  Notifications, Settings, CourseDetails, Courses, AddCourse, EditSessions,
} from '.';
import ButtonHeader from '../components/header/header';
import { GET_GYMS } from '../graphQl/graphql';
import { gym as gymConst } from '../assets/constants';

const { Content } = Layout;

function Main(props) {
  const { styles } = Main;
  const history = useHistory();
  const { loading, data } = useQuery(GET_GYMS);

  useEffect(() => {
    if (!loading && data?.getGyms) {
      const userId = JSON.parse(localStorage.getItem('user')).id;
      const userGym = data.getGyms.find((gym) => gym.owner.id === userId);
      localStorage.setItem('gymId', userGym?.id);
      localStorage.setItem('gym', JSON.stringify(userGym));
      if (props.location.pathname === '/main') history.push('/main/courses');
    }
  }, [loading, data]);

  return (
    <div style={{ ...styles.layout }}>
      <Layout style={{ height: '100%', backgroundColor: '#E1E7EC' }}>
        <Sidebar />
        <Layout style={{ overflow: 'auto', backgroundColor: '#E1E7EC' }}>
          <Content style={{ padding: '3em', overflow: 'auto' }}>
            <ButtonHeader buttonText={gymConst.add} />
            <Route
              key="main"
              exact
              path="/main"
              render={() => {}}
            />
            <Route
              key="users"
              path="/main/users"
              exact
              render={() => (
                <Users />
              )}
            />
            <Route
              key="subscriptions"
              exact
              path="/main/subscriptions"
              component={Subscriptions}
            />
            <Route
              key="add-new-plan"
              path="/main/subscriptions/add-new-plan"
              render={() => (<PlanForm type="add" />)}
            />
            <Route
              key="update-plan"
              path="/main/subscriptions/update-plan"
              render={() => (
                localStorage.getItem('plan')
                  ? <PlanForm type="update" /> : <Redirect to={{ pathname: '/main/subscriptions' }} />
              )}
            />
            <Route
              key="instructions"
              path="/main/instructions"
              exact
              render={() => (
                <Instructions />
              )}
            />
            <Route
              key="notifications"
              path="/main/notifications"
              render={() => (
                <Notifications />
              )}
            />
            <Route
              key="settings"
              path="/main/settings"
              render={() => (
                <Settings />
              )}
            />
            <Route
              key="courses"
              path="/main/courses"
              exact
              render={() => (
                <Courses />
              )}
            />
            <Route
              key="courses"
              path="/main/courses/add-course"
              render={() => (<AddCourse type="add" />)}
            />
            <Route
              key="courses"
              path="/main/courses/update-course"
              render={() => (
                localStorage.getItem('course')
                  ? <AddCourse type="update" /> : <Redirect to={{ pathname: '/main/courses' }} />
              )}
            />
            <Route
              key="courses"
              path="/main/courses/update-classes"
              render={() => (
                // localStorage.getItem('course') ?
                <EditSessions />
                // : <Redirect to={{ pathname: '/main/courses' }} />
              )}
            />
            <Route
              key="course-details"
              path="/main/courses/course-details"
              render={() => (
                localStorage.getItem('courseDetails')
                  ? <CourseDetails /> : <Redirect to={{ pathname: '/main/courses' }} />
              )}
            />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

Main.styles = {
  layout: {
    position: 'fixed',
    top: '0px',
    bottom: '0',
    right: '0',
    left: '0',
  },
};

export default withRouter(Main);
