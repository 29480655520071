import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';

import App from './App';

const AppContainer = () => {
  // const dispatch = useDispatch();

  const props = {
    // userToCreate: useSelector((state) => state.getIn(['auth', 'userToCreate'])),
  };

  return (
    <App
      {...props}
    />
  );
};

export default AppContainer;
