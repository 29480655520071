import React from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { TransparentButton } from '../buttons';
import { auth } from '../../assets/constants';

const ButtonHeader = () => {
  const history = useHistory();

  const onClickLogout = () => {
    localStorage.clear();
    history.push('/');
  };

  return (
    <Row className="padding-bottom-lg">
      <Col>
        {/* add another transparent background button */}
        <TransparentButton text={auth.logout} buttonHeight="50px" onClick={onClickLogout} />
      </Col>
    </Row>
  );
};

export default ButtonHeader;
