/* eslint-disable react/prop-types,no-nested-ternary,max-len */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Empty, List, Spin, Tag,
} from 'antd';
import { Col, Row } from 'reactstrap';
import { useQuery } from '@apollo/client';
import ContentHeader from '../components/contentHeader';
import { AlertMsgModal, CreateMsgModal } from '../components/modals/modal';
import { GET_MESSAGES } from '../graphQl/graphql';
import { forms, noData, notifications } from '../assets/constants';

function Notifications() {
  const gymId = localStorage.getItem('gymId');
  const { loading, data } = useQuery(GET_MESSAGES, { fetchPolicy: 'network-only', variables: { gymId } });
  const [messages, setMessages] = useState([]);
  const [showMessageAlertModal, setShowMessageAlertModal] = useState(false);
  const [showCreateMessageModal, setShowCreateMessageModal] = useState(false);

  useEffect(() => {
    if (!loading && data && data.getMessages) {
      setMessages(data.getMessages);
    }
  }, [loading, data]);

  return (
    <div>
      <ContentHeader
        headingName={notifications.my}
        // buttonText="Create Message This goes"
        // onClick={() => setShowCreateMessageModal(true)}
        buttonText={notifications.sendAlert}
        onClick={() => setShowMessageAlertModal(true)}
        // extraButton={{
        //   buttonText: 'Send Message Alert',
        //   onClick: () => setShowMessageAlertModal(true),
        // }}
      />
      {loading
        ? <div className="margin-top-xl text-align-last-center"><Spin /></div>
        : !messages?.length ? (
          <Col>
            <div
              className="align-center margin-top-xl margin-bottom-xl"
              style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}
            >
              <Empty description={noData} />
            </div>
          </Col>
        )
          : (
            <div className="margin-top-xl">
              <List
                itemLayout="horizontal"
                dataSource={messages}
                className="custom-list"
                renderItem={(item) => (
                  <div
                    className="item-wrapper"
                    key={item.id}
                    id={item.id}
                  >
                    <List.Item style={{ display: 'flex', width: '100%' }} className="list-on-mobile">
                      <List.Item.Meta
                        className="listMeta"
                        style={{ display: 'flex', width: '100%' }}
                        title={(
                          <Row>
                            <Col sm={4} style={{ minWidth: 'fit-content' }}>
                              <div style={{ fontSize: '22px' }} className="margin-bottom-xs">{item.title}</div>
                              <div style={{ fontSize: '14px', opacity: '0.5' }}>{forms.title}</div>
                            </Col>
                            <Col sm={3} style={{ minWidth: 'fit-content' }}>
                              <div style={{ fontSize: '22px' }} className="margin-bottom-xs">
                                {item.courses ? (
                                  <>
                                    {item.courses?.map((course) => (
                                      <Tag className="course-tags" color="geekblue">
                                        {course.name}
                                      </Tag>
                                    ))}
                                  </>
                                ) : notifications.toAll}
                              </div>
                              <div style={{ fontSize: '14px', opacity: '0.5' }}>{notifications.receipt}</div>
                            </Col>
                            {/*      { */}
                            {/*  item.course && ( */}
                            {/*  <Col sm={4} style={{ minWidth: 'fit-content' }}> */}
                            {/*    <div style={{ fontSize: '22px' }} className="margin-bottom-xs">{item.course}</div> */}
                            {/*    <div style={{ fontSize: '14px', opacity: '0.5' }}>Course</div> */}
                            {/*  </Col> */}
                            {/*  ) */}
                            {/* } */}
                          </Row>
                        )}
                      />
                    </List.Item>
                  </div>
                )}
              />
            </div>
          )}
      {
        showMessageAlertModal
        && (
        <AlertMsgModal
          handleClose={() => setShowMessageAlertModal(false)}
        />
        )
      }
      {
        showCreateMessageModal && (
        <CreateMsgModal
          handleClose={() => setShowCreateMessageModal(false)}
        />
        )
      }
    </div>
  );
}

export default Notifications;
