/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Form, Input, InputNumber, Typography,
} from 'antd';
import { Col, Row } from 'reactstrap';
import { useMutation } from '@apollo/client';
import ContentHeader from '../components/contentHeader';
import UploadComponent from '../components/upload';
import { errorMessage, successMessage } from '../utils';
import { UPDATE_GYM, UPDATE_USER } from '../graphQl/graphql';
import { MainButton, TransparentButton } from '../components/buttons';
import {
  getValidationMessage, successfulMessage,
  gym as gymConst, forms, upload, auth, settings, operations,
} from '../assets/constants';

const { Text } = Typography;
const { TextArea } = Input;

function Settings() {
  const gymId = localStorage.getItem('gymId');
  const gym = JSON.parse(localStorage.getItem('gym'));
  const gymOwner = JSON.parse(localStorage.getItem('gym')).owner;
  const [selectedSettingOption, setSelectedSettingOption] = useState(localStorage.getItem('selectedSettingOption') || 'gym');
  const [updateGymMutation] = useMutation(UPDATE_GYM);
  const [updateUserMutation] = useMutation(UPDATE_USER);
  const [loading, setIsLoading] = useState(false);
  const [logo, setLogo] = useState(gym.logo || '');
  const [logoError, setLogoError] = useState('');

  useEffect(() => {
    if (logoError.length && logo.length) {
      setLogoError('');
    }
  }, [logo]);

  useEffect(() => {
    localStorage.setItem('selectedSettingOption', 'gym');
    return () => localStorage.removeItem('selectedSettingOption');
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedSettingOption', selectedSettingOption);
  }, [selectedSettingOption]);

  const updateGym = (formData) => {
    if (!logo.length) {
      setLogoError('Logo is required');
    } else {
      const {
        name, phoneNumber, memberCount, address, description,
      } = formData;
      setIsLoading(true);
      updateGymMutation({
        variables: {
          update: {
            name,
            phoneNumber,
            memberCount,
            address,
            logo,
            description,
          },
          gymId,
        },
      })
        .then(() => {
          setIsLoading(false);
          successMessage(successfulMessage.update);
        })
        .catch((err) => {
          setIsLoading(false);
          errorMessage(err.toString());
        });
    }
  };

  const updateUser = (formData) => {
    const {
      firstName, lastName, email, password,
    } = formData;
    setIsLoading(true);
    const update = { firstName, lastName, email };
    if (password?.length) {
      update.password = password;
    }
    updateUserMutation({
      variables: { update },
    })
      .then(() => {
        setIsLoading(false);
        successMessage(successfulMessage.update);
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  const updateGymForm = (
    <Row>
      <Col sm={8}>
        <Row>
          <Col>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{gymConst.name}</Text>
            <Form.Item
              name="name"
              rules={[{ required: true, message: getValidationMessage(gymConst.name) }]}
              initialValue={gym.name || ''}
            >
              <Input className="form-field-white" />
            </Form.Item>
          </Col>
          <Col>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.contact}</Text>
            <Form.Item
              name="phoneNumber"
              rules={[{ required: true, message: getValidationMessage(forms.contact) }]}
              initialValue={gym.phoneNumber || ''}
              normalize={(value) => value && value.replace(/[^\d$]/, '')}
            >
              <Input className="form-field-white" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.address}</Text>
            <Form.Item
              name="address"
              rules={[{ required: true, message: getValidationMessage(forms.address) }]}
              initialValue={gym.address || ''}
            >
              <Input className="form-field-white" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.memberCount}</Text>
            <Form.Item
              name="memberCount"
              rules={[{ required: true, message: getValidationMessage(forms.memberCount) }]}
              initialValue={gym.memberCount || ''}
            >
              <InputNumber className="form-field-white-number" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.description}</Text>
            <Form.Item
              name="description"
              rules={[{ required: true, message: getValidationMessage(forms.description) }]}
              initialValue={gym.description || ''}
            >
              <TextArea rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={4}>
        <Text style={{ color: '#767676', fontSize: '18px' }}>{upload.gymLogo}</Text>
        <UploadComponent getUrl={(url) => setLogo(url)} initialValue={gym.logo || ''} error={logoError} />
      </Col>
    </Row>
  );

  const updateUserForm = (
    <Row>
      <Col sm={10}>
        <Row>
          <Col>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.firstName}</Text>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: getValidationMessage(forms.firstName) }]}
              initialValue={gymOwner.firstName || ''}
            >
              <Input className="form-field-white" />
            </Form.Item>
          </Col>
          <Col>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.lastName}</Text>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: getValidationMessage(forms.lastName) }]}
              initialValue={gymOwner.lastName || ''}
            >
              <Input className="form-field-white" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.email}</Text>
            <Form.Item
              name="email"
              rules={[{ required: true, message: getValidationMessage(forms.email) }]}
              initialValue={gymOwner.email || ''}
              type="email"
            >
              <Input className="form-field-white" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.password}</Text>
            <Form.Item
              name="password"
              rules={[{
                validator: (rule, value, callback) => ((value && value.length < 6)
                  ? callback(auth.passwordValidation)
                  : callback()),
              }]}
            >
              <Input className="form-field-white" type="password" />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <div>
      <ContentHeader
        headingName={settings}
        hasButton={false}
      />
      <Row className="padding-left-lg margin-bottom-lg">
        {
          selectedSettingOption === 'gym'
            ? (
              <>
                <div className="margin-right-md">
                  <TransparentButton
                    text={gymConst.single}
                    buttonHeight="50px"
                    buttonWidth="130px"
                  />
                </div>
                <MainButton
                  text={gymConst.owner}
                  buttonHeight="50px"
                  buttonWidth="130px"
                  onClick={() => setSelectedSettingOption('gymOwner')}
                />
              </>
            )
            : (
              <>
                <div className="margin-right-md">
                  <MainButton
                    text={gymConst.single}
                    buttonHeight="50px"
                    buttonWidth="130px"
                    onClick={() => setSelectedSettingOption('gym')}
                  />
                </div>
                <TransparentButton
                  text={gymConst.owner}
                  buttonHeight="50px"
                  buttonWidth="130px"
                />
              </>
            )
        }
      </Row>
      <div>
        <Form className="margin-top-xl" onFinish={selectedSettingOption === 'gym' ? updateGym : updateUser}>
          {selectedSettingOption === 'gym' ? updateGymForm : updateUserForm}
          <Row className="margin-top-md">
            <Col sm={selectedSettingOption === 'gym' ? 12 : 10}>
              <MainButton loading={loading} fontSize="20px" htmlType="submit" text={operations.update} />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default Settings;
