import React from 'react';
import './App.scss';
import Routes from './routes';

function App(props) {
  return (
    <Routes {...props} />
  );
}

export default App;
