import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  Login, Register, ResetPassword, ForgotPassword, VerificationCode,
} from './pages';
import Main from './pages/Main';

const Routes = () => ([
  <Route
    key="home"
    exact
    path="/"
    render={() => (
      localStorage.getItem('isLoggedIn') !== 'true'
        ? <Login /> : <Redirect to={{ pathname: '/main/courses' }} />
    )}
  />,
  <Route
    key="register"
    path="/register"
    render={() => (
      localStorage.getItem('isLoggedIn') !== 'true'
        ? <Register /> : <Redirect to={{ pathname: '/main/courses' }} />
    )}
  />,
  <Route
    key="login"
    path="/login"
    render={() => (
      localStorage.getItem('isLoggedIn') !== 'true'
        ? <Login /> : <Redirect to={{ pathname: '/main/courses' }} />
    )}
  />,
  <Route
    key="forgotPassword"
    path="/forgot-password"
    render={() => (
      localStorage.getItem('isLoggedIn') !== 'true'
        ? <ForgotPassword /> : <Redirect to={{ pathname: '/main/courses' }} />
    )}
  />,
  <Route
    key="verificationCode"
    path="/verification-code"
    render={() => (
      localStorage.getItem('isLoggedIn') !== 'true'
        ? <VerificationCode /> : <Redirect to={{ pathname: '/main/courses' }} />
    )}
  />,
  <Route
    key="resetPassword"
    path="/reset-password"
    render={() => (
      localStorage.getItem('isLoggedIn') !== 'true'
        ? <ResetPassword /> : <Redirect to={{ pathname: '/main/courses' }} />
    )}
  />,
  <Route
    key="main"
    path="/main"
    render={() => (
      localStorage.getItem('isLoggedIn') === 'true'
        ? <Main /> : <Redirect to={{ pathname: '/login' }} />
    )}
  />,
]);

export default Routes;
