/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Form, Input,
} from 'antd';
import Text from 'antd/es/typography/Text';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { MainButton } from '../components/buttons';
import { AuthLayout } from '../components/layout';
import { FORGOT_PASSWORD } from '../graphQl/graphql';
import { errorMessage } from '../utils';
import { auth, forms, operations } from '../assets/constants';

function ForgotPassword() {
  const history = useHistory();
  const [forgotPassword] = useMutation(FORGOT_PASSWORD);
  const [loading, setIsLoading] = useState(false);

  const onSubmit = (formData) => {
    const { email } = formData;
    setIsLoading(true);
    forgotPassword({
      variables: {
        email,
      },
    })
      .then(() => {
        setIsLoading(false);
        localStorage.setItem('email', email);
        history.push('/verification-code');
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  const forgotPasswordForm = (
    <Form className="margin-top-sm" onFinish={onSubmit}>
      <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.email}</Text>
      <Form.Item name="email">
        <Input
          className="form-field"
          type="email"
        />
      </Form.Item>
      <br />
      <br />
      <MainButton loading={loading} fontSize="20px" htmlType="submit" text={operations.submit} />
    </Form>
  );

  return (
    <AuthLayout
      form={forgotPasswordForm}
      title={auth.resetPassword}
      extra={auth.enterEmailForCode}
    />
  );
}

export default ForgotPassword;
