import { gql } from '@apollo/client';

// MUTATIONS

export const LOGIN = gql`
  mutation login($credentials: CredentialsInput!) {
    login(credentials: $credentials) {
      user {
        id
        email
        roleId
      }
      token
    }
  }
`;

export const REGISTER_GYM = gql`
  mutation registerGym($code: String!, $gym: GymInput!, $user: UserInput!) {
    registerGym(code: $code, gym: $gym, user: $user) {
      success
    }
  }
`;

export const UPDATE_GYM = gql`
  mutation updateGym($update: UpdateGymInput!, $gymId: String!) {
    updateGym(update: $update, gymId: $gymId) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation updateUser($update: UpdateUserInput!) {
    updateUser(update: $update) {
      success
    }
  }
`;

export const FORGOT_PASSWORD = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
    }
  }
`;

export const FORGOT = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email) {
      success
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation resetPassword($reset: ResetPasswordInput!) {
    resetPassword(reset: $reset) {
      success
    }
  }
`;

export const VERIFY = gql`
  mutation verify($verify: VerificationInput!) {
    verify(verify: $verify) {
      success
    }
  }
`;

export const CREATE_SUBSCRIPTION_PLAN = gql`
  mutation createSubscriptionPlan($courseIds: [ID!]!, $plan: SubscriptionplanInput!, $gymId: String!) {
    createSubscriptionPlan(courseIds: $courseIds, plan: $plan, gymId: $gymId) {
      id
      title
      description
      billingCycle
      duration
      price
      discount
      subscriberCount
      courses {
        id
        name
        description
        image
        duration
        startDate
        endDate
        coach {
          id
          firstName
          lastName
        }
        gym {
          id
          name
          description
        }
        memberCount
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION_PLAN = gql`
  mutation updateSubscriptionPlan($courseIds: [ID!]!, $update: SubscriptionplanUpdateInput!, $planId: Float!) {
    updateSubscriptionPlan(courseIds: $courseIds, update: $update, planId: $planId) {
      success
    }
  }
`;

export const ADD_CONSUMER = gql`
  mutation addConsumer($coachId: String, $planIds: [ID!]!, $consumer: GymMemberInput!, $gymId: String!) {
    addConsumer(coachId: $coachId, planIds: $planIds, consumer: $consumer, gymId: $gymId) {
      id
      firstName
      lastName
      email
      gender
      phoneNumber
      address
      subscriptionplans {
        id
        title
        description
      }
      status
      userId
      roleId
      courseCount
      photo
    }
  }
`;

export const ADD_COACH = gql`
  mutation addCoach($coach: GymMemberInput!, $gymId: String!) {
    addCoach(coach: $coach, gymId: $gymId) {
      id
      firstName
      lastName
      email
      gender
      phoneNumber
      address
      subscriptionplans {
        id
        title
        description
      }
      status
      userId
      roleId
      courseCount
      photo
    }
  }
`;

export const DELETE_MEMBER = gql`
  mutation deleteMember($memberId: String!, $gymId: String!) {
    deleteMember(memberId: $memberId, gymId: $gymId) {
      success
    }
  }
`;

export const DELETE_SUBSCRIPTION_PLAN = gql`
  mutation deleteSubscriptionPlan($planId: Float!) {
    deleteSubscriptionPlan(planId: $planId) {
      success
    }
  }
`;

export const CREATE_COURSE = gql`
  mutation createCourse($sessions: [SessionInput!]!, $course: CourseInput!, $coachId: ID!, $gymId: ID!) {
    createCourse(sessions: $sessions, course: $course, coachId: $coachId, gymId: $gymId) {
      id
      name
      description
      image
      duration
      startDate
      endDate
      coach {
        id
        firstName
        lastName
      }
      gym {
        id
        name
        description
      }
      memberCount
    }
  }
`;

export const UPDATE_COURSE = gql`
  mutation updateCourse($deletedSessions: [ID!]!, $updatedSessions: [SessionUpdateInput!], $newSessions: [SessionInput!], $update: CourseUpdateInput!, $courseId: ID!) {
    updateCourse(deletedSessions: $deletedSessions, updatedSessions: $updatedSessions, newSessions: $newSessions, update: $update, courseId: $courseId) {
      success
    }
  }
`;

export const DELETE_COURSE = gql`
  mutation deleteCourse($courseId: ID!) {
    deleteCourse(courseId: $courseId) {
      success
    }
  }
`;

export const CREATE_SESSIONS = gql`
  mutation createMultipleSessions($session: [SessionInput!]!, $courseId: ID!) {
    createMultipleSessions(session: $session, courseId: $courseId) {
      id
      startTime
      endTime
      day
      seatLimit
    }
  }
`;

export const UPDATE_SESSION = gql`
  mutation updateSession($update: SessionUpdateInput!, $sessionId: ID!) {
    updateSession(update: $update, sessionId: $sessionId) {
      success
    }
  }
`;

export const DELETE_SESSION = gql`
  mutation deleteSession($sessionId: ID!) {
    deleteSession(sessionId: $sessionId) {
      success
    }
  }
`;

export const CREATE_INSTRUCTION = gql`
  mutation createInstructions($members: [String!]!, $instructions: InstructionInput!, $gymId: String! ) {
    createInstructions(members: $members, instructions: $instructions, gymId: $gymId) {
      id
      title
      document
      startDate
      endDate
      members {
        id
        firstName
        lastName
        email
        gender
        phoneNumber
        address
        createdAt
        subscriptionplans {
          id
          title
          description
          billingCycle
          duration
          price
          discount
        }
        status
        roleId
        courseCount
      }
    }
  }
`;

export const UPDATE_INSTRUCTION = gql`
  mutation updateInstructions($membersUpdate: [String!], $instructionsUpdate: UpdateInstructionsInput, $instructionId: ID!) {
    updateInstructions(membersUpdate: $membersUpdate, instructionsUpdate: $instructionsUpdate, instructionId: $instructionId) {
      success
    }
  }
`;

export const DELETE_INSTRUCTION = gql`
  mutation deleteInstructions($instructionId: ID!) {
    deleteInstructions(instructionId: $instructionId) {
      success
    }
  }
`;

export const UPDATE_MEMBER_PLANS = gql`
  mutation updateMemberPlans($planIds: [ID!]!, $memberId: ID!, $gymId: String!) {
    updateMemberPlans(planIds: $planIds, memberId: $memberId, gymId: $gymId) {
      success
    }
  }
`;

export const UPDATE_CONSUMER = gql`
  mutation updateConsumer($planIds: [ID!]!, $memberId: ID!, $gymId: String!, $coachId: ID!) {
    updateMemberPlans(planIds: $planIds, memberId: $memberId, gymId: $gymId) {
      success
    }
    updateMemberCoach(coachId: $coachId, memberId: $memberId, gymId: $gymId) {
      success
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createMessage($courses: [ID!], $message: MessageInput!, $gymId: String!) {
    createMessage(courses: $courses, message: $message, gymId: $gymId) {
      id
      title
      message
      type
      courses {
        id
        name
        description
        image
        duration
        startDate
        endDate
        coach {
          id
          firstName
          lastName
          email
          gender
          phoneNumber
          address
          createdAt
          subscriptionplans {
            id
            title
            description
          }
          status
          roleId
          courseCount
        }
      }
    }
  }
`;

// QUERIES

export const GET_GYMS = gql`
  query getGyms {
    getGyms {
      id
      name
      phoneNumber
      address
      memberCount
      description
      createdAt
      logo
      owner {
        id
        firstName
        lastName
        email
      }
    }
  }
`;

export const GET_CONSUMERS = gql`
  query getConsumers($gymId: String!) {
    getConsumers(gymId: $gymId) {
      id
      firstName
      lastName
      email
      gender
      phoneNumber
      address
      coachId
      subscriptionplans {
        id
        title
        description
      }
      courses {
        id
        name
        startDate
        endDate
        coach {
          id
          firstName
          lastName
        }
      }
      status
      userId
      roleId
      courseCount
      photo
      code
    }
  }
`;

export const GET_COACHES = gql`
  query getCoaches($gymId: String!) {
    getCoaches(gymId: $gymId) {
      id
      firstName
      lastName
      email
      gender
      phoneNumber
      address
      subscriptionplans {
        id
        title
        description
      }
      courses {
        id
        name
        startDate
        endDate
        coach {
          id
          firstName
          lastName
        }
      }
      status
      userId
      roleId
      courseCount
      photo
      code
    }
  }
`;

export const GET_SUBSCRIPTION_PLANS = gql`
  query getSubscriptionPlans($gymId: String!, $memberId: String) {
    getSubscriptionPlans(gymId: $gymId, memberId: $memberId) {
      id
      title
      description
      billingCycle
      duration
      price
      discount
      subscriberCount
      courses {
        id
        name
        description
        startDate
        endDate
        coach {
          id
          firstName
          lastName
        }
      }
    }
  }
`;

export const GET_COURSES = gql`
  query getCourses($memberId: String, $gymId: String!) {
    getCourses(memberId: $memberId, gymId: $gymId) {
      id
      name
      description
      image
      duration
      startDate
      endDate
      coach {
        id
        firstName
        lastName
        email
        gender
        phoneNumber
        address
        createdAt
        status
        roleId
        userId
        courseCount
      }
      gym {
        id
        name
        description
        memberCount
      }
      memberCount
    }
  }
`;

export const GET_COURSE_DETAILS = gql`
  query getCourseDetails($courseId: ID!) {
    getCourseDetails(courseId: $courseId) {
      id
      name
      description
      image
      duration
      startDate
      endDate
      coach {
        id
        firstName
        lastName
        phoneNumber
        address
        createdAt
        status
        roleId
        userId
        courseCount
      }
      gym {
        id
        name
        description
        memberCount
      }
      memberCount
      sessions {
        id
        startTime
        endTime
        day
        seatLimit
      }
    }
  }
`;

export const GET_SESSIONS = gql`
  query getSessions($courseId: ID!, $gymId: ID) {
    getSessions(courseId: $courseId, gymId: $gymId ) {
      id
      startTime
      endTime
      day
      seatLimit
    }
  }
`;

export const GET_INSTRUCTIONS = gql`
  query getInstructions($gymId: String!) {
    getInstructions(gymId: $gymId) {
      id
      title
      document
      startDate
      endDate
      members {
        id
        firstName
        lastName
        email
        gender
        phoneNumber
        address
        createdAt
        subscriptionplans {
          id
          title
          description
          billingCycle
          duration
          price
          discount
        }
        status
        roleId
        courseCount
      }
    }
  }
`;

export const GET_MESSAGES = gql`
  query getMessages($gymId: String!) {
    getMessages(gymId: $gymId) {
      id
      title
      message
      type
      courses {
        id
        name
        description
        image
        duration
        startDate
        endDate
        coach {
          id
          firstName
          lastName
          email
          gender
          phoneNumber
          address
          createdAt
          subscriptionplans {
            id
            title
            description
            billingCycle
            duration
            price
            discount
          }
          status
          roleId
          courseCount
        }
      }
    }
  }
`;
