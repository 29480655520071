/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,max-len,dot-notation,no-prototype-builtins,no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions,no-unused-vars */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Form, Typography, TimePicker, InputNumber, Spin, Tooltip, Select, Divider, Popconfirm, Empty,
} from 'antd';
import { Col, Row } from 'reactstrap';
import { SaveFilled } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { replace } from 'lodash';
import ContentHeader from '../components/contentHeader';
import {
  UPDATE_SESSION, DELETE_SESSION, GET_COURSES, GET_SESSIONS,
} from '../graphQl/graphql';
import { errorMessage, successMessage } from '../utils';
import deleteIcon from '../assets/Images/deleteIcon.svg';
import {
  courses, day, forms, operations, successfulMessage, noData, monthsMap, daysMap,
} from '../assets/constants';

const { Text } = Typography;
const FormItem = Form.Item;
const { Option } = Select;

function EditSessions() {
  const gymId = localStorage.getItem('gymId');
  const course = JSON.parse(localStorage.getItem('course')) || {};
  const history = useHistory();
  const [updateSessionMutation] = useMutation(UPDATE_SESSION);
  const [deleteSessionMutation] = useMutation(DELETE_SESSION);
  const [filteredSessions, setFilteredSessions] = useState([]);
  const [selectedFilter, setSelectedFilter] = useState('7');
  const { loading, data, refetch } = useQuery(GET_SESSIONS, { variables: { courseId: course?.id, gymId } });
  const [form] = Form.useForm();

  const translateDate = (date) => {
    // eslint-disable-next-line no-shadow
    const day = date.split(' ')[0];
    const month = date.split(' ')[1];
    const newDate = replace(date, month, monthsMap[month]);

    return replace(newDate, day, daysMap[day]);
  };

  useEffect(() => () => localStorage.removeItem('course'), []);

  useEffect(() => {
    if (!Object.keys(course).length) history.push('/main/courses');
  }, [course]);

  useEffect(() => {
    if (data?.getSessions) {
      setFilteredSessions(data.getSessions.filter((session) => moment(session.startTime).isBetween(moment(), moment().add(selectedFilter, 'd'))));
    }
  }, [data]);

  const updateSession = (sessionData) => {
    const {
      startTime, endTime, seatLimit, sessionId,
    } = sessionData;
    setFilteredSessions(filteredSessions.map((session) => (session.id === sessionId ? { ...session, isEditing: true } : session)));
    updateSessionMutation({
      variables: {
        refetchQueries: [{ query: GET_COURSES, variables: { gymId } }, { query: GET_SESSIONS, variables: { courseId: course.id, gymId } }],
        update: {
          startTime,
          endTime,
          seatLimit,
        },
        sessionId,
      },
    })
      .then(() => {
        refetch();
        successMessage(successfulMessage.updateCourse);
      })
      .catch((err) => {
        setFilteredSessions(filteredSessions.map((session) => (session.id === sessionId ? { ...session, isEditing: false } : session)));
        errorMessage(err.toString());
      });
  };

  const onDeleteSession = (id) => {
    setFilteredSessions(data?.getSessions.map((session) => (session.id === id ? { ...session, isDeleting: true } : session)));
    deleteSessionMutation({
      refetchQueries: [{ query: GET_COURSES, variables: { gymId } }, { query: GET_SESSIONS, variables: { courseId: course.id, gymId } }],
      variables: {
        sessionId: id,
      },
    })
      .then(() => {
        refetch();
        successMessage(successfulMessage.delete);
      })
      .catch((err) => {
        setFilteredSessions(data?.getSessions.map((session) => (session.id === id ? { ...session, isDeleting: false } : session)));
        errorMessage(err.toString());
      });
  };

  const onFilterSessions = (filter) => {
    setSelectedFilter(filter);
    setFilteredSessions(data?.getSessions?.filter((session) => moment(session.startTime).isBetween(moment(), moment().add(filter, 'd'))));
  };

  const getSessionFields = (session) => {
    let { startTime, endTime, seatLimit } = session;
    return (
      <Row key={session.id} className="margin-top-md margin-bottom-md flex">
        <Col style={{ alignSelf: 'center', textAlign: 'center' }}>
          <Text style={{ fontSize: '18px' }}>{translateDate(moment(session.startTime).format('ddd ll').toLowerCase()).toUpperCase()}</Text>
        </Col>
        <Col sm={2}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.startTime}</Text>
          <FormItem name={`startTime-${session.id}`} initialValue={moment.utc(session.startTime).local()}>
            <TimePicker
              className="form-field-white"
              showNow={false}
              showSecond={false}
              format="HH:mm"
              onChange={(val) => {
                startTime = val;
              }}
            />
          </FormItem>
        </Col>
        <Col sm={2}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.endTime}</Text>
          <FormItem name={`endTime-${session.id}`} initialValue={moment.utc(session.endTime).local()}>
            <TimePicker
              className="form-field-white"
              showNow={false}
              showSecond={false}
              format="HH:mm"
              onChange={(val) => {
                endTime = val;
              }}
            />
          </FormItem>
        </Col>
        <Col sm={2}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.noOfSeats}</Text>
          <FormItem name={`seatLimit-${session.id}`} initialValue={session.seatLimit}>
            <InputNumber
              onChange={(val) => {
                seatLimit = val;
              }}
              className="form-field-white-number"
            />
          </FormItem>
        </Col>
        <Col style={{ alignSelf: 'center' }}>
          <span
            className="selectable"
            onClick={() => updateSession({
              startTime, endTime, seatLimit, sessionId: session.id,
            })}
          >
            {session.isEditing ? <Spin className="margin-left-xs margin-right-md margin-top-xs" /> : (
              <Tooltip title="Click to save">
                <SaveFilled className="blue" style={{ fontSize: '28px' }} />
              </Tooltip>
            )}
          </span>
          <span className="selectable" onClick={() => onDeleteSession(session.id)}>
            {session.isDeleting
              ? <Spin className="margin-right-sm margin-left-lg margin-top-xs" />
              : (
                <img
                  src={deleteIcon}
                  alt="delete"
                  className="margin-right-lg margin-left-lg"
                  style={{ height: '28px', color: session.isEditing && 'gray', cursor: session.isEditing && 'not-allowed' }}
                />
              )}
          </span>
        </Col>
        <Col sm={3} />
      </Row>
    );
  };

  return (
    <div>
      <ContentHeader
        headingName={courses.editClasses}
        buttonText={operations.save}
        linkTo="/main/courses"
      />
      <div>
        <Form form={form} className="margin-top-xl" onFinish={() => history.push('/main/courses')}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.selectFilter}</Text>
          <FormItem name="filter" initialValue={selectedFilter}>
            <div className="form-select-white">
              <Select style={{ width: '30%' }} onChange={onFilterSessions} defaultValue={selectedFilter}>
                <Option value="7" key="7">{day.seven}</Option>
                <Option value="14" key="14">{day.fourteen}</Option>
                <Option value="30" key="30">{day.thirty}</Option>
              </Select>
            </div>
          </FormItem>
          <Divider />
          <br />
          {loading ? <Spin className="margin-right-lg margin-left-sm margin-top-xs" />
            : filteredSessions?.length ? filteredSessions.map((session) => getSessionFields(session))
              : (
                <Col>
                  <div
                    className="align-center margin-top-xl margin-bottom-xl"
                    style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}
                  >
                    <Empty description={noData} />
                  </div>
                </Col>
              )}
        </Form>
      </div>
    </div>
  );
}

export default EditSessions;
