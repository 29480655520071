import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { BrowserRouter, Switch } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  from,
  HttpLink,
} from '@apollo/client';
import { onError } from 'apollo-link-error';
import { setContext } from '@apollo/client/link/context';
import App from './App.container';
import reportWebVitals from './reportWebVitals';

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => console.log(`GRAPHQL ERROR ${message}`));
  }
});

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from local storage if it exists
  let token = localStorage.getItem('authorizationToken');
  token = JSON.parse(token);
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const link = from([
  errorLink,
  new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URI,
  }),
]);

// Initialize Apollo Client
const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <>
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Switch>
          <App />
        </Switch>
      </BrowserRouter>
    </ApolloProvider>
  </>,
  document.getElementById('root'),
);

reportWebVitals();
