/* eslint-disable react/prop-types,no-nested-ternary,max-len,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import {
  Spin, Empty, Menu, Dropdown,
} from 'antd';
import moment from 'moment';
import editIcon from '../assets/Images/editIcon.svg';
import deleteIcon from '../assets/Images/deleteIcon.svg';
import ContentHeader from '../components/contentHeader';
import { DELETE_COURSE, GET_COURSES } from '../graphQl/graphql';
import { errorMessage, successMessage } from '../utils';
import {
  courses as coursesConst, operations, successfulMessage, noData,
} from '../assets/constants';

function Courses() {
  const gymId = localStorage.getItem('gymId');
  const { loading, data } = useQuery(GET_COURSES, { fetchPolicy: 'network-only', variables: { gymId } });
  const [deleteCourse] = useMutation(DELETE_COURSE);
  const [courses, setCourses] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (!loading && data && data.getCourses) {
      setCourses(data.getCourses);
    }
  }, [loading, data]);

  const onEdit = (item, type) => {
    localStorage.setItem('course', JSON.stringify(item));
    history.push(type === 'course' ? '/main/courses/update-course' : '/main/courses/update-classes');
  };

  const editOptions = (item) => (
    <Menu style={{ borderRadius: '6px' }}>
      <Menu.Item>
        <div onClick={() => onEdit(item, 'course')}>
          {coursesConst.editCourse}
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => onEdit(item, 'session')}>
          {coursesConst.editClasses}
        </div>
      </Menu.Item>
    </Menu>
  );

  const onDelete = (courseId) => {
    setCourses(courses.map((plan) => (plan.id === courseId ? { ...plan, loading: true } : plan)));
    deleteCourse({
      refetchQueries: [{ query: GET_COURSES, variables: { gymId } }],
      variables: {
        courseId,
      },
    })
      .then(() => {
        setCourses(courses.map((plan) => (plan.id === courseId ? { ...plan, loading: false } : plan)));
        successMessage(successfulMessage.delete);
      })
      .catch((err) => {
        setCourses(courses.map((plan) => (plan.id === courseId ? { ...plan, loading: false } : plan)));
        errorMessage(err.toString());
      });
  };

  return (
    <div>
      <ContentHeader headingName={coursesConst.self} buttonText={coursesConst.addNew} linkTo="/main/courses/add-course" />
      {loading
        ? <div className="margin-top-xl text-align-last-center"><Spin /></div>
        : !courses?.length ? (
          <Col>
            <div
              className="align-center margin-top-xl margin-bottom-xl"
              style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}
            >
              <Empty description={noData} />
            </div>
          </Col>
        )
          : (
            <Row>
              {courses?.map((item) => (
                <Col sm={3}>
                  <Col>
                    <Card
                      className="shadow p-3 mb-1"
                      style={{ borderRadius: '10px', width: '100%' }}
                    >
                      <Row className="margin-top-sm flex">
                        <Col sm={8}>
                          <div
                            className="margin-left-sm"
                            style={{
                              background: '#CCF8FE',
                              display: 'flex',
                              borderRadius: '8px',
                              width: 'max-content',
                              fontSize: '12px',
                              padding: '0.8em',
                              paddingLeft: '0.8em',
                              alignItems: 'center',
                            }}
                          >
                            <>
                              {item.coach?.firstName}
                              &nbsp;
                              {item.coach?.lastName}
                            </>
                          </div>
                        </Col>
                        <Col>
                          <div className="flex" style={{ float: 'right', paddingRight: '0' }}>
                            <Dropdown placement="bottomRight" overlay={editOptions(item)}>
                              <span className="selectable">
                                <img
                                  src={editIcon}
                                  alt="edit"
                                  className="margin-right-sm"
                                  style={{ height: '18px' }}
                                />
                              </span>
                            </Dropdown>
                            <span className="selectable" onClick={() => onDelete(item.id)}>
                              {
                                item.loading
                                  ? <Spin className="margin-right-lg margin-left-sm margin-top-xs" />
                                  : (
                                    <img
                                      src={deleteIcon}
                                      alt="delete"
                                      className="margin-right-lg margin-left-sm margin-top-xs"
                                      style={{ height: '18px' }}
                                    />
                                  )
                              }
                            </span>
                          </div>
                        </Col>
                      </Row>
                      {
                        item.image?.length > 0
                        && (
                          <div
                            className="margin-x-md"
                            style={{
                              width: '98%', height: '250px', alignSelf: 'center', textAlignLast: 'center',
                            }}
                          >
                            <img
                              style={{ width: 'inherit', height: 'inherit', borderRadius: '10px' }}
                              src={item.image}
                              alt=""
                            />
                          </div>
                        )
                      }
                      <Row>
                        <div
                          style={{
                            display: 'flex',
                            borderRadius: '8px',
                            width: '250px',
                            fontSize: '20px',
                            paddingLeft: '1.25em',
                            marginTop: item.image?.length > 0 ? '0' : '1em',
                            marginBottom: '0.5em',
                          }}
                        >
                          {item.name}
                        </div>
                      </Row>
                      <Row>
                        <div
                          style={{
                            display: 'flex',
                            borderRadius: '8px',
                            width: '250px',
                            fontSize: '14px',
                            color: 'grey',
                            paddingLeft: '1.8em',
                          }}
                        >
                          {item.description}
                        </div>
                      </Row>
                      <Row className="margin-top-md">
                        <Col sm={8}>
                          <div
                            className="padding-left-xs"
                            style={{
                              display: 'flex',
                              borderRadius: '8px',
                              width: '250px',
                              fontSize: '12px',
                              fontWeight: '700',
                              paddingLeft: '1em',
                              marginBottom: '1em',
                            }}
                          >
                            <>
                              {moment(item.startDate).format('DD MMM YYYY')}
                              {' '}
                              to
                              {' '}
                              {moment(item.endDate).format('DD MMM YYYY')}
                            </>
                          </div>
                        </Col>
                        <Col>
                          <div style={{ float: 'right', paddingRight: '1em' }}>
                            <a
                              style={{ fontSize: '13px' }}
                              href="/main/courses/course-details"
                              onClick={() => localStorage.setItem('courseDetails', JSON.stringify(item))}
                            >
                              {operations.details}
                            </a>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                    <br />
                  </Col>
                </Col>
              ))}
            </Row>
          )}
    </div>
  );
}

export default Courses;
