/* eslint-disable react/prop-types,react/no-string-refs */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Form, Input,
} from 'antd';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { MainButton } from '../components/buttons';
import { AuthLayout } from '../components/layout';

function VerificationCode() {
  const history = useHistory();

  const onSubmit = (formData) => {
    const {
      codeOne,
      codeTwo,
      codeThree,
      codeFour,
      codeFive,
      codeSix,
    } = formData;
    localStorage.setItem('verificationCode', codeOne + codeTwo + codeThree + codeFour + codeFive + codeSix);
    history.push('/reset-password');
  };

  const [inputRef2, setInputRef2] = useState(null);
  const [inputRef3, setInputRef3] = useState(null);
  const [inputRef4, setInputRef4] = useState(null);
  const [inputRef5, setInputRef5] = useState(null);
  const [inputRef6, setInputRef6] = useState(null);

  const verificationForm = (
    <Form className="margin-top-sm" onFinish={onSubmit}>
      <Input.Group>
        <Row>
          <Col>
            <Form.Item
              name="codeOne"
              normalize={(value) => value && value.replace(/[^\d$]/, '')}
            >
              <Input
                onChange={(e) => e.target.value && inputRef2.focus()}
                className="form-field text-align-center"
                maxLength="1"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="codeTwo"
              normalize={(value) => value && value.replace(/[^\d$]/, '')}
            >
              <Input
                onChange={(e) => e.target.value && inputRef3.focus()}
                ref={(input) => setInputRef2(input)}
                className="form-field text-align-center"
                maxLength="1"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="codeThree"
              normalize={(value) => value && value.replace(/[^\d$]/, '')}
            >
              <Input
                onChange={(e) => e.target.value && inputRef4.focus()}
                ref={(input) => setInputRef3(input)}
                className="form-field text-align-center"
                maxLength="1"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="codeFour"
              normalize={(value) => value && value.replace(/[^\d$]/, '')}
            >
              <Input
                onChange={(e) => e.target.value && inputRef5.focus()}
                ref={(input) => setInputRef4(input)}
                className="form-field text-align-center"
                maxLength="1"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="codeFive"
              normalize={(value) => value && value.replace(/[^\d$]/, '')}
            >
              <Input
                onChange={(e) => e.target.value && inputRef6.focus()}
                ref={(input) => setInputRef5(input)}
                className="form-field text-align-center"
                maxLength="1"
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item
              name="codeSix"
              normalize={(value) => value && value.replace(/[^\d$]/, '')}
            >
              <Input
                ref={(input) => setInputRef6(input)}
                className="form-field text-align-center"
                maxLength="1"
              />
            </Form.Item>
          </Col>
        </Row>
      </Input.Group>
      <br />
      <br />
      <MainButton htmlType="submit" fontSize="20px" text="Submit" />
    </Form>
  );

  return (
    <AuthLayout
      form={verificationForm}
      title="Verification Code"
      extra="We have sent you a 6 digit verification code. Check your email"
    />
  );
}

export default VerificationCode;
