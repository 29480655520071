/* eslint-disable react/prop-types,max-len */
/* eslint-disable import/no-unresolved */
import React, { useEffect } from 'react';
import { Col, Row } from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import moment from 'moment';
import back from '../assets/Images/back.svg';
import { courses, operations } from '../assets/constants';

function CourseDetails() {
  const history = useHistory();
  const courseDetails = JSON.parse(localStorage.getItem('courseDetails')) || {};

  useEffect(() => () => localStorage.removeItem('courseDetails'), []);

  useEffect(() => {
    if (!Object.keys(courseDetails).length) history.push('/main/courses');
  }, [courseDetails]);

  return (
    <div>
      <div className="flex margin-top-xl">
        <Col sm={1}>
          <Link to="/main/courses">
            <img className="margin-top-md" src={back} alt="backIcon" />
          </Link>
        </Col>
        <Col style={{ marginLeft: '-4em' }}>
          <Row>
            <Col>
              <div style={{ color: '#333333', fontWeight: '700', fontSize: '35px' }}>{courseDetails.name}</div>
              <div style={{ fontWeight: '500', fontSize: '20px', opacity: '0.6' }} className="margin-top-sm">
                <>
                  di
                  &nbsp;
                  {courseDetails.coach?.firstName}
                  &nbsp;
                  {courseDetails.coach?.lastName}
                </>
              </div>
            </Col>
          </Row>
        </Col>
      </div>
      <Row className="padding-left-lg margin-top-xl">
        <Col>
          <div className="margin-bottom-md margin-top-md" style={{ fontWeight: '500', fontSize: '22px' }}>{operations.overview}</div>
          <div className="margin-bottom-lg" style={{ fontWeight: '400', fontSize: '20px', opacity: '0.5' }}>
            {courseDetails.description}
          </div>
          <div className="margin-top-lg" style={{ fontWeight: '500', fontSize: '18px', color: '#5663F0' }}>
            <>
              {courses.startDate}
              :
              &nbsp;
              <span style={{ color: '#00000099', fontWeight: '400' }}>
                {moment(courseDetails.startDate).format('DD MMM YYYY')}
              </span>
            </>
          </div>
          <div className="margin-top-sm" style={{ fontWeight: '500', fontSize: '18px', color: '#5663F0' }}>
            <>
              {courses.endDate}
              :&nbsp;
              <span style={{ color: '#00000099', fontWeight: '400' }}>
                {moment(courseDetails.endDate).format('DD MMM YYYY')}
              </span>
            </>
          </div>
          {/* <div className="margin-top-sm" style={{ fontWeight: '500', fontSize: '18px', color: '#5663F0' }}> */}
          {/*  <> */}
          {/*    Schedule:&nbsp; */}
          {/*    <div className="margin-top-sm margin-left-lg" style={{ fontWeight: '400', fontSize: '16px', color: '#00000099' }}> */}
          {/*      <> */}
          {/*        Monday:&nbsp; */}
          {/*        {courseDetails.schedule.monday?.length ? courseDetails.schedule.monday.map((timing) => ( */}
          {/*          <Tag> */}
          {/*            <> */}
          {/*              {timing.startTime} */}
          {/*              -*/}
          {/*              {timing.endTime} */}
          {/*            </> */}
          {/*          </Tag> */}
          {/*        )) */}
          {/*          : <span style={{ color: '#5663F0', fontWeight: '400' }}>-</span>} */}
          {/*      </> */}
          {/*    </div> */}
          {/*    <div className="margin-top-sm margin-left-lg" style={{ fontWeight: '400', fontSize: '16px', color: '#00000099' }}> */}
          {/*      <> */}
          {/*        Tuesday:&nbsp; */}
          {/*        {courseDetails.schedule.tuesday?.length ? courseDetails.schedule.tuesday.map((timing) => ( */}
          {/*          <Tag> */}
          {/*            <> */}
          {/*              {timing.startTime} */}
          {/*              -*/}
          {/*              {timing.endTime} */}
          {/*            </> */}
          {/*          </Tag> */}
          {/*        )) */}
          {/*          : <span style={{ color: '#5663F0', fontWeight: '400' }}>-</span>} */}
          {/*      </> */}
          {/*    </div> */}
          {/*    <div className="margin-top-sm margin-left-lg" style={{ fontWeight: '400', fontSize: '16px', color: '#00000099' }}> */}
          {/*      <> */}
          {/*        Wednesday:&nbsp; */}
          {/*        {courseDetails.schedule.wednesday?.length ? courseDetails.schedule.wednesday.map((timing) => ( */}
          {/*          <Tag> */}
          {/*            <> */}
          {/*              {timing.startTime} */}
          {/*              -*/}
          {/*              {timing.endTime} */}
          {/*            </> */}
          {/*          </Tag> */}
          {/*        )) */}
          {/*          : <span style={{ color: '#5663F0', fontWeight: '400' }}>-</span>} */}
          {/*      </> */}
          {/*    </div> */}
          {/*    <div className="margin-top-sm margin-left-lg" style={{ fontWeight: '400', fontSize: '16px', color: '#00000099' }}> */}
          {/*      <> */}
          {/*        Thursday:&nbsp; */}
          {/*        {courseDetails.schedule.thursday?.length ? courseDetails.schedule.thursday.map((timing) => ( */}
          {/*          <Tag> */}
          {/*            <> */}
          {/*              {timing.startTime} */}
          {/*              -*/}
          {/*              {timing.endTime} */}
          {/*            </> */}
          {/*          </Tag> */}
          {/*        )) */}
          {/*          : <span style={{ color: '#5663F0', fontWeight: '400' }}>-</span>} */}
          {/*      </> */}
          {/*    </div> */}
          {/*    <div className="margin-top-sm margin-left-lg" style={{ fontWeight: '400', fontSize: '16px', color: '#00000099' }}> */}
          {/*      <> */}
          {/*        Friday:&nbsp; */}
          {/*        {courseDetails.schedule.friday?.length ? courseDetails.schedule.friday.map((timing) => ( */}
          {/*          <Tag> */}
          {/*            <> */}
          {/*              {timing.startTime} */}
          {/*              -*/}
          {/*              {timing.endTime} */}
          {/*            </> */}
          {/*          </Tag> */}
          {/*        )) */}
          {/*          : <span style={{ color: '#5663F0', fontWeight: '400' }}>-</span>} */}
          {/*      </> */}
          {/*    </div> */}
          {/*    <div className="margin-top-sm margin-left-lg" style={{ fontWeight: '400', fontSize: '16px', color: '#00000099' }}> */}
          {/*      <> */}
          {/*        Saturday:&nbsp; */}
          {/*        {courseDetails.schedule.saturday?.length ? courseDetails.schedule.saturday.map((timing) => ( */}
          {/*          <Tag> */}
          {/*            <> */}
          {/*              {timing.startTime} */}
          {/*              -*/}
          {/*              {timing.endTime} */}
          {/*            </> */}
          {/*          </Tag> */}
          {/*        )) */}
          {/*          : <span style={{ color: '#5663F0', fontWeight: '400' }}>-</span>} */}
          {/*      </> */}
          {/*    </div> */}
          {/*    <div className="margin-top-sm margin-left-lg" style={{ fontWeight: '400', fontSize: '16px', color: '#00000099' }}> */}
          {/*      <> */}
          {/*        Sunday:&nbsp; */}
          {/*        {courseDetails.schedule.sunday?.length ? courseDetails.schedule.sunday.map((timing) => ( */}
          {/*          <Tag color="geekblue"> */}
          {/*            <> */}
          {/*              {timing.startTime} */}
          {/*              -*/}
          {/*              {timing.endTime} */}
          {/*            </> */}
          {/*          </Tag> */}
          {/*        )) */}
          {/*          : <span style={{ color: '#5663F0', fontWeight: '400' }}>-</span>} */}
          {/*      </> */}
          {/*    </div> */}
          {/*  </> */}
          {/* </div> */}
        </Col>
        {courseDetails.image && (
        <Col sm={6} className="align-center">
          <img
            style={{
              height: '35em', width: '35em', borderRadius: '10px',
            }}
            src={courseDetails.image}
            alt="courseImage"
          />
        </Col>
        )}
      </Row>
    </div>
  );
}

export default CourseDetails;
