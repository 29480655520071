/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Form, Input,
} from 'antd';
import Text from 'antd/es/typography/Text';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { MainButton } from '../components/buttons';
import { AuthLayout } from '../components/layout';
import { errorMessage, openNotification } from '../utils';
import { RESET_PASSWORD } from '../graphQl/graphql';
import {
  auth, forms, operations, successfulMessage,
} from '../assets/constants';

function ResetPassword() {
  const history = useHistory();
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const [loading, setIsLoading] = useState(false);

  const onSubmit = (formData) => {
    const { password } = formData;
    setIsLoading(true);
    resetPassword({
      variables: {
        reset: {
          code: localStorage.getItem('verificationCode'),
          email: localStorage.getItem('email'),
          newPassword: password,
        },
      },
    })
      .then(() => {
        setIsLoading(false);
        openNotification({
          type: 'success',
          title: auth.resetPassword,
          description: successfulMessage.update,
        });
        localStorage.removeItem('verificationCode');
        localStorage.removeItem('email');
        history.push('/login');
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  const resetPasswordForm = (
    <Form className="margin-top-sm" onFinish={onSubmit}>
      <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.password}</Text>
      <Form.Item name="password">
        <Input
          className="form-field password-field"
          type="password"
        />
      </Form.Item>
      <br />
      <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.confirmPassword}</Text>
      <Form.Item name="confirm-password">
        <Input
          className="form-field password-field"
          type="password"
        />
      </Form.Item>
      <br />
      <br />
      <MainButton loading={loading} fontSize="20px" htmlType="submit" text={operations.submit} />
    </Form>
  );

  return (
    <AuthLayout
      form={resetPasswordForm}
      title={auth.resetPassword}
      extra={auth.enterNewPassword}
    />
  );
}

export default ResetPassword;
