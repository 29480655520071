/* eslint-disable react/prop-types,jsx-a11y/click-events-have-key-events,max-len,dot-notation,no-prototype-builtins,no-unused-expressions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Form, Input, Typography, Select, DatePicker,
  Divider, Tag, TimePicker, Tooltip, InputNumber,
} from 'antd';
import { Col, Row } from 'reactstrap';
import Icon, { CheckCircleTwoTone } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import ContentHeader from '../components/contentHeader';
import { MainButton, TransparentButton } from '../components/buttons';
import calender from '../assets/Images/calender.svg';
import addIcon from '../assets/Images/addIcon.svg';
import crossIcon from '../assets/Images/cross.svg';
import UploadComponent from '../components/upload';
import {
  CREATE_COURSE, UPDATE_COURSE, GET_COACHES, GET_COURSES,
} from '../graphQl/graphql';
import { errorMessage, successMessage } from '../utils';
import {
  courses, day as dayConst, getValidationMessage, operations, successfulMessage,
} from '../assets/constants';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function AddCourse({ type }) {
  const gymId = localStorage.getItem('gymId');
  const course = JSON.parse(localStorage.getItem('course')) || {};
  const [nextStep, setNextStep] = useState(false);
  const [stepOneData, setStepOneData] = useState({});
  const [selectedDay, setSelectedDay] = useState('Monday');
  const [day, setDay] = useState('monday');
  const days = [
    { value: 'Monday', label: dayConst.monday },
    { value: 'Tuesday', label: dayConst.tuesday },
    { value: 'Wednesday', label: dayConst.wednesday },
    { value: 'Thursday', label: dayConst.thursday },
    { value: 'Friday', label: dayConst.friday },
    { value: 'Saturday', label: dayConst.saturday },
    { value: 'Sunday', label: dayConst.sunday },
  ];
  const history = useHistory();
  const [addCourseMutation] = useMutation(CREATE_COURSE);
  const [updateCourseMutation] = useMutation(UPDATE_COURSE);
  const [loading, setIsLoading] = useState(false);
  const [courseImage, setCourseImage] = useState('');
  const [image, setImage] = useState(course?.image || '');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [sessions, setSessions] = useState([]);
  const [todaysSessions, setTodaysSessions] = useState([]);
  const { loading: coachesLoading, data: coachesData } = useQuery(GET_COACHES, { variables: { gymId } });
  const [imageError, setImageError] = useState('');
  const [daysToAdd, setDaysToAdd] = useState([]);
  const [seatLimit, setSeatLimit] = useState(null);
  const [form] = Form.useForm();

  const { resetFields } = form;
  useEffect(() => {
    if (imageError.length && image.length) {
      setImageError('');
    }
  }, [image]);

  const [coaches, setCoaches] = useState([]);
  const [coachId, setCoachId] = useState(course?.coach?.id || '');

  useEffect(() => {
    if (!coachesLoading && coachesData && coachesData.getCoaches) {
      setCoaches(coachesData.getCoaches);
    }
  }, [coachesLoading, coachesData]);

  useEffect(() => {
    setDay(selectedDay.toLowerCase());
  }, [selectedDay]);

  useEffect(() => () => localStorage.removeItem('course'), []);

  useEffect(() => {
    if (type === 'update' && !Object.keys(course).length) {
      history.push('/main/courses');
    }
  }, [type, course]);

  const onAddSession = () => {
    if (startTime && endTime && daysToAdd?.length && seatLimit) {
      daysToAdd.forEach((dayToAdd) => setSessions((latestSessions) => [...latestSessions, {
        startTime, endTime, seatLimit, day: dayToAdd.toUpperCase(),
      }]));
      resetFields(['startTime', 'endTime', 'seatLimit', 'daysToAdd']);
      setStartTime('');
      setEndTime('');
      setDaysToAdd([]);
      setSeatLimit(null);
    }
  };

  useEffect(() => {
    setTodaysSessions(sessions.filter((session) => session.day === day.toUpperCase()));
  }, [sessions, day]);

  const onDeleteSession = (sessionToDelete) => {
    setSessions(sessions.filter((session) => !(session.startTime === sessionToDelete.startTime
      && session.endTime === sessionToDelete.endTime
      && session.day === sessionToDelete.day
      && session.seatLimit === sessionToDelete.seatLimit)));
  };

  const addCourse = (formData) => {
    if (!nextStep) {
      if (!image.length) {
        setImageError(getValidationMessage(courses.image));
      } else {
        setStepOneData(formData);
        setNextStep(true);
      }
    } else {
      const { startDate, endDate } = formData;
      setIsLoading(true);
      addCourseMutation({
        refetchQueries: [{ query: GET_COURSES, variables: { gymId } }],
        variables: {
          sessions,
          course: {
            name: stepOneData.name,
            description: stepOneData.description,
            image,
            startDate: moment.utc(startDate),
            endDate: moment.utc(endDate),
            schedule: {},
            duration: '20',
          },
          coachId,
          gymId,
        },
      })
        .then(() => {
          setIsLoading(false);
          successMessage(successfulMessage.addCourse);
          history.push('/main/courses');
        })
        .catch((err) => {
          setIsLoading(false);
          errorMessage(err.toString());
        });
    }
  };

  const updateCourse = (formData) => {
    if (!image.length) {
      setImageError(getValidationMessage(courses.image));
    } else {
      const {
        startDate, endDate, name, description,
      } = formData;
      setIsLoading(true);
      updateCourseMutation({
        refetchQueries: [{ query: GET_COURSES, variables: { gymId } }],
        variables: {
          update: {
            name,
            description,
            image,
            startDate: moment.utc(startDate),
            endDate: moment.utc(endDate),
            schedule: {},
            coachId,
          },
          deletedSessions: [],
          courseId: course.id,
        },
      })
        .then(() => {
          setIsLoading(false);
          successMessage(successfulMessage.updateCourse);
          history.push('/main/courses');
        })
        .catch((err) => {
          setIsLoading(false);
          errorMessage(err.toString());
        });
    }
  };

  const firstStep = (
    <Row>
      <Col sm={8}>
        <Row>
          <Col>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.name}</Text>
            <Form.Item
              name="name"
              rules={[{ required: true, message: getValidationMessage(courses.name) }]}
              initialValue={course.name || ''}
            >
              <Input className="form-field-white" />
            </Form.Item>
          </Col>
          <Col>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.coachName}</Text>
            <Form.Item
              name="coachId"
              rules={[{ required: !coachId.length, message: getValidationMessage(courses.coachName) }]}
              initialValue={course?.coach?.id || ''}
            >
              <div className="form-select-white">
                <Select onChange={(val) => setCoachId(val)} loading={coachesLoading} value={coachId || course?.coach?.id}>
                  {coaches?.map((coach) => (
                    <Option key={coach.id} value={coach.id}>
                      <>
                        {coach.firstName}
                        &nbsp;
                        {coach.lastName}
                      </>
                    </Option>
                  ))}
                </Select>
              </div>
            </Form.Item>
          </Col>
        </Row>
        {
          type === 'update' && (
          <Row>
            <Col>
              <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.scheduleStartDate}</Text>
              <Form.Item
                name="startDate"
                rules={[{ required: true, message: getValidationMessage(courses.startDate) }]}
                initialValue={Object.keys(course).length > 0 ? moment(course?.startDate) : undefined}
              >
                <DatePicker
                  className="form-field-white"
                  format="DD-MM-YYYY"
                  suffixIcon={(
                    <Icon
                      component={() => (
                        <img
                          src={calender}
                          alt="calender"
                          style={{ height: '20px' }}
                        />
                      )}
                    />
                )}
                />
              </Form.Item>
            </Col>
            <Col>
              <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.scheduleEndDate}</Text>
              <Form.Item
                name="endDate"
                rules={[{ required: true, message: getValidationMessage(courses.endDate) }]}
                initialValue={Object.keys(course).length > 0 ? moment(course?.endDate) : undefined}
              >
                <DatePicker
                  className="form-field-white"
                  format="DD-MM-YYYY"
                  suffixIcon={(
                    <Icon
                      component={() => (
                        <img
                          src={calender}
                          alt="calender"
                          style={{ height: '20px' }}
                        />
                      )}
                    />
                )}
                />
              </Form.Item>
            </Col>
          </Row>
          )
        }
        <Row>
          <Col>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.courseDescription}</Text>
            <Form.Item
              name="description"
              rules={[{ required: true, message: getValidationMessage(courses.courseDescription) }]}
              initialValue={course.description || ''}
            >
              <TextArea rows={15} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Col sm={4}>
        <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.image}</Text>
        <UploadComponent
          getFile={(file) => setCourseImage(file)}
          getUrl={(url) => setImage(url)}
          error={imageError}
        />
        {
          type === 'add'
            ? courseImage.length > 0 && (
            <div className="margin-top-md" style={{ color: 'gray', display: 'flex', justifyContent: 'center' }}>
              {(courseImage?.name?.length > 15 ? (
                <Tooltip title={courseImage.name}>
                  <span>
                    {courseImage.name.substring(0, 15)}
                    ...
                  </span>
                </Tooltip>
              ) : <>{courseImage.name}</>)}
              &nbsp;
              uploaded successfully
              &nbsp;
              <CheckCircleTwoTone style={{ marginTop: '4px' }} twoToneColor="#52c41a" />
            </div>
            ) : image.length > 0 && (
            <div className="margin-top-md" style={{ color: 'gray', display: 'flex', justifyContent: 'center' }}>
              {(image?.length > 15 ? (
                <Tooltip title={image}>
                  <span>
                    {image.substring(0, 15)}
                    ...
                  </span>
                </Tooltip>
              ) : <>{image}</>)}
              &nbsp;
              {successfulMessage.upload}
              &nbsp;
              <CheckCircleTwoTone style={{ marginTop: '4px' }} twoToneColor="#52c41a" />
            </div>
            )
         }
      </Col>
    </Row>
  );

  const timePick = (
    <>
      <Row
        className={(selectedDay === 'Saturday' || selectedDay === 'Sunday') ? '' : 'margin-top-xl margin-bottom-md flex'}
      >
        <Col sm={3}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{dayConst.plural}</Text>
          <Form.Item name="daysToAdd">
            <div className="form-select-white">
              <Select placeholder={dayConst.plural} onChange={(val) => setDaysToAdd(val)} mode="multiple">
                {days.map((_day) => <Option value={_day.value.toLowerCase()} key={_day.value}>{_day.label}</Option>)}
              </Select>
            </div>
          </Form.Item>
        </Col>
        <Col sm={2}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.startTime}</Text>
          <Form.Item name="startTime">
            <TimePicker
              className="form-field-white"
              showNow={false}
              showSecond={false}
              format="HH:mm"
              onChange={(val, timeString) => setStartTime(timeString)}
            />
          </Form.Item>
        </Col>
        <Col sm={2}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.endTime}</Text>
          <Form.Item name="endTime">
            <TimePicker
              className="form-field-white"
              showNow={false}
              showSecond={false}
              format="HH:mm"
              onChange={(val, timeString) => setEndTime(timeString)}
            />
          </Form.Item>
        </Col>
        <Col sm={2}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.noOfSeats}</Text>
          <Form.Item name="seatLimit">
            <InputNumber onChange={(val) => setSeatLimit(val)} className="form-field-white-number" />
          </Form.Item>
        </Col>
        <Col>
          <span className="selectable" onClick={() => onAddSession()}>
            <Icon
              className="margin-top-xl"
              component={() => (
                <img
                  src={addIcon}
                  alt="addIcon"
                />
              )}
            />
          </span>
        </Col>
      </Row>
    </>
  );

  const todaysTags = (
    <>
      {todaysSessions?.map((session) => (
        <Tag
          className="time-tags"
          closable
          closeIcon={(
            <Icon
              className="margin-top-xl margin-left-sm"
              component={() => (
                <img
                  src={crossIcon}
                  style={{ verticalAlign: 'sub', height: '18px' }}
                  alt="crossIcon"
                />
              )}
            />
      )}
          onClose={() => onDeleteSession(session)}
        >
          <b>
            {session.startTime}
            -
            {session.endTime}
          </b>
          <Divider type="vertical" />
          <b>
            {courses.seats}
            :&nbsp;
            {session.seatLimit}
          </b>
        </Tag>
      ))}
    </>
  );

  const secondStep = (
    <div>
      <Row>
        <Col sm={4}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.scheduleStartDate}</Text>
          <Form.Item
            name="startDate"
            rules={[{ required: true, message: getValidationMessage(courses.startDate) }]}
            initialValue={Object.keys(course).length > 0 ? moment(course?.startDate) : undefined}
          >
            <DatePicker
              className="form-field-white"
              format="DD-MM-YYYY"
              suffixIcon={(
                <Icon
                  component={() => (
                    <img
                      src={calender}
                      alt="calender"
                      style={{ height: '20px' }}
                    />
                  )}
                />
              )}
            />
          </Form.Item>
        </Col>
        <Col sm={4}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{courses.scheduleEndDate}</Text>
          <Form.Item
            name="endDate"
            rules={[{ required: true, message: getValidationMessage(courses.endDate) }]}
            initialValue={Object.keys(course).length > 0 ? moment(course?.endDate) : undefined}
          >
            <DatePicker
              className="form-field-white"
              format="DD-MM-YYYY"
              suffixIcon={(
                <Icon
                  component={() => (
                    <img
                      src={calender}
                      alt="calender"
                      style={{ height: '20px' }}
                    />
                  )}
                />
              )}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
      {timePick}
      <Row className="margin-top-lg">
        {
          days.map((_day) => (
            _day.value === selectedDay
              ? (
                <div className="margin-bottom-sm">
                  <MainButton
                    text={_day.label}
                    buttonHeight="50px"
                    buttonWidth="140px"
                    marginLeft
                  />
                </div>
              )
              : (
                <div className="margin-bottom-sm">
                  <TransparentButton
                    text={_day.label}
                    buttonHeight="50px"
                    buttonWidth="140px"
                    onClick={() => setSelectedDay(_day.value)}
                    marginLeft
                  />
                </div>
              )))
        }
      </Row>
      <div className="margin-bottom-xl">
        {todaysSessions.length
          ? todaysTags
          : <p className="margin-left-md margin-top-md" style={{ fontStyle: 'italic', color: '#00000077', fontSize: '15px' }}>{courses.noClassesMessage}</p>}
      </div>
    </div>
  );

  return (
    <div>
      <ContentHeader
        headingName={type === 'add' ? courses.add : courses.update}
        hasButton={false}
      />
      <div>
        <Form form={form} className="margin-top-xl" onFinish={type === 'add' ? addCourse : updateCourse}>
          {nextStep ? secondStep : firstStep}
          <Row className="margin-top-md">
            <Col>
              <MainButton
                loading={loading}
                fontSize="20px"
                htmlType="submit"
                text={type === 'update' ? operations.save : nextStep ? operations.save : operations.next}
              />
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default AddCourse;
