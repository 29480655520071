/* eslint-disable react/prop-types,no-nested-ternary,max-len,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import { Divider, Empty, Spin } from 'antd';
import { Col, Row } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import ContentHeader from '../components/contentHeader';
import pdfIcon from '../assets/Images/pdfIcon.svg';
import editIcon from '../assets/Images/editIcon.svg';
import deleteIcon from '../assets/Images/deleteIcon.svg';
import { AddInstructionsModal } from '../components/modals';
import { DELETE_INSTRUCTION, GET_INSTRUCTIONS } from '../graphQl/graphql';
import { errorMessage, successMessage } from '../utils';
import {
  successfulMessage, noData, instructions as instructionsConst, operations,
} from '../assets/constants';

function Instructions() {
  const gymId = localStorage.getItem('gymId');
  const [showAddInstructionsModal, setShowAddInstructionsModal] = useState(false);
  const [formType, setFormType] = useState('add');
  const { loading, data } = useQuery(GET_INSTRUCTIONS, { fetchPolicy: 'network-only', variables: { gymId } });
  const [deleteInstruction] = useMutation(DELETE_INSTRUCTION);
  const [instructions, setInstructions] = useState([]);

  useEffect(() => {
    if (!loading && data && data.getInstructions) {
      setInstructions(data.getInstructions);
    }
  }, [loading, data]);

  const onEdit = (item) => {
    localStorage.setItem('instruction', JSON.stringify(item));
    setFormType('update');
    setShowAddInstructionsModal(true);
  };

  const onDelete = (instructionId) => {
    setInstructions(instructions.map((plan) => (plan.id === instructionId ? { ...plan, loading: true } : plan)));
    deleteInstruction({
      refetchQueries: [{ query: GET_INSTRUCTIONS, variables: { gymId } }],
      variables: {
        instructionId,
      },
    })
      .then(() => {
        setInstructions(instructions.map((plan) => (plan.id === instructionId ? { ...plan, loading: false } : plan)));
        successMessage(successfulMessage.delete);
      })
      .catch((err) => {
        setInstructions(instructions.map((plan) => (plan.id === instructionId ? { ...plan, loading: false } : plan)));
        errorMessage(err.toString());
      });
  };

  return (
    <div>
      <ContentHeader
        headingName={instructionsConst.beginner}
        buttonText={operations.add}
        onClick={() => setShowAddInstructionsModal(true)}
      />
      <div>
        {loading
          ? <div className="margin-top-xl text-align-last-center"><Spin /></div>
          : !instructions?.length ? (
            <Col>
              <div
                className="align-center margin-top-xl margin-bottom-xl"
                style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}
              >
                <Empty description={noData} />
              </div>
            </Col>
          )
            : (
              instructions?.map((item) => (
                <Row>
                  <Col sm={5}>
                    <div
                      className="durationPeriod"
                      style={{
                        marginTop: '2.7em',
                        width: '-webkit-fill-available',
                        height: '70px',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        paddingLeft: '2em',
                        paddingRight: '2em',
                        verticalAlign: 'center',
                      }}
                    >
                      <Row>
                        <Col sm={2} className="align-center">
                          <img src={pdfIcon} alt="icon" className="margin-top-md" />
                        </Col>
                        <Col sm={1}>
                          <div className="padding-bottom-sm" style={{ height: '100%' }}>
                            <Divider type="vertical" style={{ height: '100%' }} className="margin-top-md" />
                          </div>
                        </Col>
                        <Col className="align-self-center padding-y-sm">
                          <div className="margin-top-lg">
                            {item.title}
                          </div>
                        </Col>
                        <Col sm={3} className="margin-top-md">
                          <Row className="float-right margin-top-sm padding-right-md" style={{ alignSelf: 'flex-end' }}>
                            <span className="selectable" onClick={() => onEdit(item)}>
                              <img
                                src={editIcon}
                                alt="edit"
                                className="margin-right-sm"
                                style={{ height: '18px' }}
                              />
                            </span>
                            <span className="selectable" onClick={() => onDelete(item.id)}>
                              {
                                item.loading
                                  ? <Spin className="margin-right-lg margin-left-sm margin-top-xs" />
                                  : (
                                    <img
                                      src={deleteIcon}
                                      alt="delete"
                                      className="margin-right-lg margin-left-sm"
                                      style={{ height: '18px' }}
                                    />
                                  )
                              }
                            </span>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              )))}
      </div>
      {
        showAddInstructionsModal
        && (
          <AddInstructionsModal
            handleClose={() => setShowAddInstructionsModal(false)}
            type={formType}
          />
        )
      }
    </div>
  );
}

export default Instructions;
