// eslint-disable-next-line max-len
/* eslint-disable no-nested-ternary,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,import/prefer-default-export,max-len */
import {
  Avatar, Empty, List, Popconfirm, Spin,
} from 'antd';
import { Col, Row } from 'reactstrap';
import React from 'react';
import profile from '../assets/Images/profile.png';
import editIcon from '../assets/Images/editIcon.svg';
import deleteIcon from '../assets/Images/deleteIcon.svg';
import {
  confirmation,
  courses, forms, noData,
} from '../assets/constants';

export const usersList = (props) => {
  const {
    allowEdit, loading, users, setShowCoursesModal, handleDelete, handleEdit, setCourses,
  } = props;
  return (
    <>
      {loading
        ? <div className="margin-top-xl text-align-last-center"><Spin /></div>
        : !users?.length ? (
          <Col>
            <div
              className="align-center margin-top-xl margin-bottom-xl"
              style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}
            >
              <Empty description={noData} />
            </div>
          </Col>
        )
          : (
            <List
              itemLayout="horizontal"
              dataSource={users}
              className="custom-list"
              renderItem={(item) => (
                <div
                  className="item-wrapper"
                  key={item.id}
                  id={item.id}
                >
                  <List.Item style={{ display: 'flex', width: '100%' }} className="list-on-mobile">
                    <List.Item.Meta
                      className="listMeta"
                      style={{ display: 'flex', width: '100%' }}
                      avatar={(
                        <Avatar
                          size="large"
                          className="hide-on-mobile-portrait"
                          src={<img src={item.photo || profile} style={{ objectFit: 'cover' }} alt={item.name} />}
                        />
                      )}
                      title={(
                        <Row>
                          <Avatar
                            size="large"
                            className="show-on-mobile-portrait"
                            src={<img src={item.photo || profile} alt={item.name} />}
                          />
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div style={{ fontSize: '20px' }} className="margin-bottom-xs">
                              {
                                <>
                                  {item.firstName}
                                  &nbsp;
                                  {item.lastName}
                                </>
                                || '-'
                              }
                            </div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{forms.firstName}</div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div
                              onClick={() => {
                                setCourses(item.courses);
                                setShowCoursesModal(true);
                              }}
                              style={{ fontSize: '20px' }}
                              className="margin-bottom-xs courses selectable"
                            >
                              {item.courseCount || 0}
                            </div>
                            <div
                              style={{ fontSize: '14px', opacity: '0.5' }}
                            >
                              {courses.total}
                            </div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div
                              style={{ fontSize: '20px', color: '#5663F0' }}
                              className="margin-bottom-xs"
                            >
                              {item.email}
                            </div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{forms.email}</div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div style={{ fontSize: '20px' }} className="margin-bottom-xs">{item.phoneNumber}</div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{forms.contact}</div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }}>
                            <div style={{ fontSize: '20px' }} className="margin-bottom-xs">{item.code}</div>
                            <div style={{ fontSize: '14px', opacity: '0.5' }}>{forms.voucherCode}</div>
                          </Col>
                          <Col style={{ minWidth: 'fit-content' }} className="margin-top-md">
                            <Row>
                              {allowEdit && (
                              <Col>
                                <span
                                  className="selectable"
                                  onClick={() => handleEdit(item)}
                                  style={{ float: 'right' }}
                                >
                                  <img
                                    src={editIcon}
                                    alt={item.name}
                                    style={{ height: '22px' }}
                                  />
                                </span>
                              </Col>
                              )}
                              <Col>
                                <span
                                  className="selectable"
                                  style={{ float: 'right', paddingRight: '3em' }}
                                >
                                  {
                                    item.loading
                                      ? <Spin />
                                      : (
                                        <Popconfirm
                                          placement="topLeft"
                                          title={confirmation.message}
                                          cancelText={confirmation.cancel}
                                          okText={confirmation.yes}
                                          onConfirm={() => handleDelete(item.id)}
                                        >
                                          <img
                                            src={deleteIcon}
                                            alt={item.name}
                                            style={{ height: '22px' }}
                                          />
                                        </Popconfirm>
                                      )
                                  }
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )}
                    />
                  </List.Item>
                </div>
              )}
            />
          )}
    </>
  );
};
