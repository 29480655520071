/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { MainButton } from '../buttons';

const ContentHeader = (props) => {
  const {
    headingName, buttonText, linkTo, onClick, extraButton, htmlType, loading, hasButton,
  } = props;

  return (
    <div>
      <Row className="margin-top-md margin-bottom-lg">
        <Col style={{ marginLeft: '0.5em' }}>
          <div style={{ fontSize: '36px', fontWeight: '500' }}>
            {headingName}
          </div>
        </Col>
        {hasButton && (
        <Col>
          <Link to={linkTo !== '' && linkTo}>
            <MainButton loading={loading} htmlType={htmlType} text={buttonText} onClick={onClick} />
          </Link>
          {
            Object.keys(extraButton).length > 0
            && (
              <Link to={extraButton.linkTo !== '' && extraButton.linkTo}>
                <MainButton
                  text={extraButton.buttonText}
                  onClick={extraButton.onClick}
                  marginRight
                />
              </Link>
            )
          }
        </Col>
        )}
      </Row>
    </div>
  );
};

ContentHeader.propTypes = {
  headingName: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  linkTo: PropTypes.string,
  onClick: PropTypes.func,
  extraButton: PropTypes.object,
  htmlType: PropTypes.string,
  loading: PropTypes.bool,
  hasButton: PropTypes.bool,
};

ContentHeader.defaultProps = {
  linkTo: '',
  onClick: () => {},
  extraButton: {},
  htmlType: null,
  loading: false,
  hasButton: true,
  buttonText: '',
};

export default ContentHeader;
