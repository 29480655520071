export const auth = {
  loginToContinue: 'Accedi per continuare',
  forgotPassword: 'Ha dimenticato la password?',
  dontHaveAccount: 'Non hai un account?',
  singUp: 'Iscriviti!',
  login: 'Login',
  resetPassword: 'Resetta la password',
  enterEmailForCode: 'Inserisci il tuo indirizzo email e ti invieremo un codice di recupero',
  registerToContinue: 'Registrati per continuare',
  alreadyHaveAccount: 'Hai già un account?',
  logout: 'Disconnettersi',
  passwordValidation: 'La password deve essere più lunga o uguale a 6 caratteri',
  enterNewPassword: 'Inserisci la tua nuova password',
};

export const courses = {
  self: 'Corsi',
  name: 'Nome del Attività',
  coachName: 'Nome del Manager',
  addNew: 'Aggiungi nuovo Attività',
  add: 'Aggiungi Attività',
  update: 'Aggiorni Attività',
  image: 'Immagine del Attività',
  seats: 'Posti',
  description: 'Descrizione del Attività',
  scheduleStartDate: 'Data di inizio programma',
  scheduleEndDate: 'Data di fine programma',
  startTime: 'Ora di inizio',
  endTime: 'Tempo scaduto',
  noOfSeats: 'Numero di posti',
  noClassesMessage: 'Nessuna lezione in programma per questo giorno',
  total: 'Corsi totali',
  editClasses: 'Modifica classi',
  editCourse: 'Modifica Attività',
  startDate: "Data d'inizio",
  endDate: 'Data di fine',
  courseDescription: 'Descrizione Attività',
};

export const user = {
  users: 'Utenti',
  consumer: 'Clienti',
  coach: 'Manager',
  addNew: 'Aggiungi nuovo utente',
  type: 'Tipologia di utente',
  edit: 'Modifica utente',
  add: 'Aggiungi utente',
};

export const subscriptionPlans = {
  self: 'Piani di abbonamento',
  add: 'Crea nuovo piano',
  update: 'Aggiorni piano',
  editCourses: 'Modifica attività',
  editPlan: 'Modifica piano',
  planCourses: 'Piano corsi',
};

export const day = {
  single: 'Giorno',
  plural: 'Giorni',
  seven: '7 giorni',
  fourteen: '14 giorni',
  thirty: '30 giorni',
  monday: 'Lunedì',
  tuesday: 'Martedì',
  wednesday: 'Mercoledì',
  thursday: 'Giovedi',
  friday: 'Venerdì',
  saturday: 'Sabato',
  sunday: 'Domenica',
};

export const forms = {
  email: 'E-mail',
  password: 'Password',
  confirmPassword: 'Conferma password',
  fullName: 'Nome e cognome',
  memberCount: 'Numero di membri',
  contact: 'Numero di contatto',
  voucherCode: 'Codice Voucher',
  description: 'Descrizione',
  address: 'Indirizzo',
  validation: 'è obbligatorio',
  select: 'Seleziona',
  firstName: 'Nome',
  lastName: 'Cognome',
  assignCoach: 'Assegna Manager',
  title: 'Titolo',
  cost: 'Costo',
  discount: 'Sconto',
  message: 'Messaggio',
  file: 'File',
  selectUsers: 'Seleziona utenti',
  selectFilter: 'Seleziona filtro',
  selectDate: 'Seleziona data',
  search: 'Ricerca',
};

export const duration = {
  base: 'Durata in base a',
  daily: 'Quotidiano',
  monthly: 'Mensile',
  annually: 'Annualmente',
  total: 'Durata totale',
  self: 'Durata',
};

export const status = {
  self: 'Stato',
  active: 'Attivo',
  inactive: 'Inattivo',
};

export const gender = {
  self: 'Genere',
  female: 'Femmina',
  male: 'Maschio',
};

export const upload = {
  gymLogo: 'Carica il logo della struttura',
  pdf: 'Carica pdf',
};

export const notifications = {
  my: 'Le mie notifiche',
  sendAlert: 'Invia messaggio di avviso',
  receipt: 'Iicevuta',
  toAll: 'a tutti',
};

export const instructions = {
  self: 'Istruzioni',
  beginner: "Schede d'allenamento",
  sendAlert: 'Invia messaggio di avviso',
  add: "Aggiungi Scheda d'allenamento",
};

export const operations = {
  submit: 'Invia',
  next: 'Prossimo',
  create: 'Creare',
  save: 'Salva',
  edit: 'Modificare',
  delete: 'Elimina',
  details: 'Particolari',
  send: 'Spedire',
  add: 'Aggiungere',
  update: 'Aggiornare',
  overview: 'Panoramica',
};

export const successfulMessage = {
  login: 'Accesso riuscito',
  register: 'Registrazione riuscita',
  addCourse: 'Attività aggiunta con successo',
  updateCourse: 'Attività aggiornata con successo',
  participantInvited: 'Partecipante invitato',
  upload: 'caricato con successo',
  userInvited: "L'utente è stato invitato con successo",
  addPlan: 'Piano aggiunto con successo',
  updatePlan: 'Piano aggiornato con successo',
  createMessageAlert: 'Avviso messaggio creato con successo',
  addInstruction: 'Scheda aggiunta con successo',
  updateInstruction: 'Scheda aggiornata con successo',
  success: 'Successo',
  delete: 'Cancellato con successo',
  update: 'Aggiornato con successo',
};

export const settings = 'Impostazioni';

export const gym = {
  single: 'Struttura',
  plural: 'Strutture',
  owner: 'Proprietario della struttura',
  name: 'Nome della struttura',
  add: 'Aggiungi Struttura',
};

export const confirmation = {
  message: 'Sei sicuro?',
  yes: 'Si',
  cancel: 'Annulla',
};

export const noData = 'Nessun dato';

export const getValidationMessage = (item) => `${item} ${forms.validation}`;

export const monthsMap = {
  jan: 'gen',
  feb: 'feb',
  mar: 'mar',
  apr: 'apr',
  may: 'mag',
  jun: 'giu',
  jul: 'lug',
  aug: 'ago',
  sep: 'set',
  oct: 'ott',
  nov: 'nov',
  dec: 'dic',
};

export const daysMap = {
  mon: 'lun',
  tue: 'mar',
  wed: 'mer',
  thu: 'gio',
  fri: 'ven',
  sat: 'sab',
  sun: 'dom',
};
