import { notification, message } from 'antd';

const openNotification = (props) => {
  notification[props.type]({
    message: props.title,
    description: props.description,
  });
};

const destroyNotifications = () => {
  notification.destroy();
};

const successMessage = (content) => {
  message.success(content);
};
const errorMessage = (content) => {
  message.error(content);
};
const warningMessage = (content) => {
  message.warning(content);
};
const infoMessage = (content) => {
  message.info(content);
};

export {
  openNotification,
  destroyNotifications,
  successMessage,
  errorMessage,
  warningMessage,
  infoMessage,
};
