/* eslint-disable max-len,react/prop-types,no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  DatePicker, Divider,
  Form, Input, List, Modal, Select, Switch, Avatar, Checkbox, Empty,
} from 'antd';
import Text from 'antd/es/typography/Text';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import Icon, { CloseCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { MainButton } from '../buttons';
import mailIcon from '../../assets/Images/mailIcon.svg';
import profile from '../../assets/Images/profile.png';
import calender from '../../assets/Images/calender.svg';
import {
  GET_SUBSCRIPTION_PLANS, ADD_COACH, ADD_CONSUMER, GET_COACHES, GET_CONSUMERS, GET_COURSES,
  CREATE_INSTRUCTION, UPDATE_INSTRUCTION, CREATE_MESSAGE, GET_INSTRUCTIONS, GET_MESSAGES,
  UPDATE_SUBSCRIPTION_PLAN, UPDATE_CONSUMER,
} from '../../graphQl/graphql';
import { errorMessage, successMessage } from '../../utils';
import UploadComponent from '../upload';
import {
  auth,
  status as statusConst,
  gender as genderConst,
  courses as coursesConst,
  forms,
  getValidationMessage,
  instructions, notifications,
  operations, subscriptionPlans as plans,
  successfulMessage,
  upload, user,
} from '../../assets/constants';

const { Option } = Select;

const ParticipantInvitedModal = ({ handleClose }) => (
  <div>
    <Modal
      visible
      onCancel={handleClose}
      keyboard
      footer={null}
      width={900}
      closable={false}
      bodyStyle={{ height: 'auto', padding: '8%' }}
      className="align-center"
    >
      <h3 className="margin-bottom-md">{successfulMessage.participantInvited}</h3>
      <img className="margin-bottom-lg" src={mailIcon} alt="mailIcon" />
      <h5 className="margin-bottom-md" style={{ color: '#767676' }}>
        {successfulMessage.userInvited}
      </h5>
      <MainButton fontSize="20px" text="Ok" float="none" width="30px" onClick={handleClose} />
    </Modal>
  </div>
);

const AddUserModal = ({ forEdit, handleAdd, handleClose }) => {
  const gymId = localStorage.getItem('gymId');
  const consumerToUpdate = forEdit ? JSON.parse(localStorage.getItem('consumerToUpdate')) : null;
  const { loading: subscriptionPlansLoading, data: subscriptionPlanData } = useQuery(GET_SUBSCRIPTION_PLANS, { variables: { gymId } });
  const { loading: coachesLoading, data: coachesData } = useQuery(GET_COACHES, { variables: { gymId } });

  const [subscriptionPlansArray, setSubscriptionPlansArray] = useState([]);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    if (!subscriptionPlansLoading && subscriptionPlanData && subscriptionPlanData.getSubscriptionPlans) {
      setSubscriptionPlansArray(subscriptionPlanData.getSubscriptionPlans);
    }
    if (!coachesLoading && coachesData && coachesData.getCoaches) {
      setCoaches(coachesData.getCoaches);
    }
  }, [subscriptionPlansLoading, subscriptionPlanData, coachesLoading, coachesData]);

  const [userType, setUserType] = useState('');
  const [addCoachMutation] = useMutation(ADD_COACH);
  const [addConsumerMutation] = useMutation(ADD_CONSUMER);
  const [updateConsumerMutation] = useMutation(UPDATE_CONSUMER);
  const [loading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(true);

  useEffect(() => () => localStorage.removeItem('consumerToUpdate'), []);

  useEffect(() => {
    if (forEdit) setUserType('consumer');
  }, [forEdit]);

  const addConsumer = (formData) => {
    const {
      firstName, lastName, email, gender, phoneNumber, address, subscriptionPlans, coachId,
    } = formData;
    setIsLoading(true);
    addConsumerMutation({
      refetchQueries: [{ query: GET_CONSUMERS, variables: { gymId } }],
      variables: {
        planIds: subscriptionPlans,
        coachId: coachId || '',
        consumer: {
          firstName,
          lastName,
          email,
          gender,
          phoneNumber,
          address,
          status: status ? 'Active' : 'Inactive',
        },
        gymId,
      },
    })
      .then(() => {
        setIsLoading(false);
        handleAdd();
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  const addCoach = (formData) => {
    const {
      firstName, lastName, email, gender, phoneNumber, address,
    } = formData;
    setIsLoading(true);
    addCoachMutation({
      refetchQueries: [{ query: GET_COACHES, variables: { gymId } }],
      variables: {
        coach: {
          firstName,
          lastName,
          email,
          gender,
          phoneNumber,
          address,
          status: status ? 'Active' : 'Inactive',
        },
        gymId,
      },
    })
      .then(() => {
        setIsLoading(false);
        handleAdd();
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  const updateConsumer = ({ subscriptionPlans, coachId }) => {
    setIsLoading(true);
    updateConsumerMutation({
      refetchQueries: [{ query: GET_CONSUMERS, variables: { gymId } }],
      variables: {
        gymId,
        coachId,
        planIds: subscriptionPlans,
        memberId: consumerToUpdate.id,
      },
    })
      .then(() => {
        setIsLoading(false);
        successMessage(successfulMessage.update);
        handleClose();
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  return (
    <Modal
      visible
      onCancel={handleClose}
      keyboard
      footer={null}
      width={1200}
      closeIcon={<CloseCircleOutlined style={{ fontSize: '26px', verticalAlign: 'center', marginTop: '1em' }} />}
      bodyStyle={{ height: 'auto' }}
      className="align-center"
      closable
    >
      <h3 className="margin-left-lg margin-top-md align-left">{forEdit ? user.edit : user.add}</h3>
      <Form onFinish={forEdit ? updateConsumer : userType === 'consumer' ? addConsumer : addCoach} className="margin-top-sm padding-xl">
        <Row className="align-left">
          <Col>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{user.type}</Text>
            <Form.Item
              name="user"
              rules={[{ required: true, message: getValidationMessage(user.type) }]}
              initialValue={forEdit ? 'consumer' : ''}
            >
              <Select
                onChange={(val) => setUserType(val)}
                disabled={forEdit}
              >
                <Option key="consumer" value="consumer">{user.consumer}</Option>
                <Option key="coach" value="coach">{user.coach}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.firstName}</Text>
            <Form.Item
              name="firstName"
              rules={[{ required: true, message: getValidationMessage(forms.firstName) }]}
              initialValue={consumerToUpdate?.firstName || ''}
            >
              <Input
                className="form-field"
                disabled={forEdit}
              />
            </Form.Item>
          </Col>
          <Col>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.lastName}</Text>
            <Form.Item
              name="lastName"
              rules={[{ required: true, message: getValidationMessage(forms.lastName) }]}
              initialValue={consumerToUpdate?.lastName || ''}
            >
              <Input
                className="form-field"
                disabled={forEdit}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align-left">
          <Col sm={4}>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{genderConst.self}</Text>
            <Form.Item
              name="gender"
              rules={[{ required: true, message: getValidationMessage(genderConst.self) }]}
              initialValue={consumerToUpdate?.gender || ''}
            >
              <Select disabled={forEdit}>
                <Option key="male" value="male">{genderConst.male}</Option>
                <Option key="female" value="female">{genderConst.female}</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col sm={4}>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.email}</Text>
            <Form.Item
              name="email"
              rules={[{ required: true, message: getValidationMessage(forms.email) }]}
              initialValue={consumerToUpdate?.email || ''}
            >
              <Input
                className="form-field"
                type="email"
                disabled={forEdit}
              />
            </Form.Item>
          </Col>
          {userType === 'consumer' && (
          <Col sm={4}>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.assignCoach}</Text>
            <Form.Item
              name="coachId"
              initialValue={consumerToUpdate?.coachId || ''}
            >
              <Select
                loading={coachesLoading}
              >
                {coaches?.map((coach) => (
                  <Option key={coach.id} value={coach.id}>
                    <>
                      {coach.firstName}
                      &nbsp;
                      {coach.lastName}
                    </>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          )}
        </Row>
        <Row className="align-left">
          <Col sm={4}>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.contact}</Text>
            <Form.Item
              name="phoneNumber"
              rules={[{ required: true, message: getValidationMessage(forms.contact) }]}
              initialValue={consumerToUpdate?.phoneNumber || ''}
            >
              <Input
                className="form-field"
                type="text"
                disabled={forEdit}
              />
            </Form.Item>
          </Col>
          <Col>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.address}</Text>
            <Form.Item
              name="address"
              rules={[{ required: true, message: getValidationMessage(forms.address) }]}
              initialValue={consumerToUpdate?.address || ''}
            >
              <Input
                className="form-field"
                type="text"
                disabled={forEdit}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="align-left">
          {userType === 'consumer' && (
          <Col sm={4}>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{plans.self}</Text>
            <Form.Item
              name="subscriptionPlans"
              rules={[{ required: true, message: getValidationMessage(plans.self) }]}
              initialValue={consumerToUpdate?.subscriptionplans?.map((plan) => plan.id)}
            >
              <Select
                mode="multiple"
                loading={subscriptionPlansLoading}
              >
                {subscriptionPlansArray?.map((subscriptionPlan) => (
                  <Option key={subscriptionPlan.id} value={subscriptionPlan.id}>
                    {subscriptionPlan.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          )}
          <Col sm={4}>
            <Text style={{ color: '#767676', fontSize: '16px' }}>{statusConst.self}</Text>
            <Form.Item name="status">
              <Row className="margin-top-xl margin-left-md">
                <Switch
                  checked={consumerToUpdate?.firstName || status}
                  onChange={(val) => setStatus(val)}
                  checkedChildren={statusConst.active}
                  unCheckedChildren={statusConst.inactive}
                  disabled={forEdit}
                />
              </Row>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <MainButton
              fontSize="20px"
              loading={loading}
              text={forEdit ? operations.update : operations.add}
              htmlType="submit"
            />
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const UserCoursesModal = ({ handleClose, courses }) => (
  <div>
    <Modal
      visible
      onCancel={handleClose}
      keyboard
      footer={null}
      width={1100}
      closeIcon={<CloseCircleOutlined style={{ fontSize: '26px', verticalAlign: 'center', marginTop: '1em' }} />}
      bodyStyle={{ height: 'auto', padding: '5%' }}
      className="align-center"
      closable
    >
      <h3 className="margin-bottom-lg align-left">{coursesConst.self}</h3>
      <List
        itemLayout="horizontal"
        dataSource={courses}
        className="custom-list"
        style={{ padding: '3%', height: '40%', overflowY: 'auto' }}
        renderItem={(item) => (
          <div
            className="item-wrapper align-left"
            key={item.id}
            style={{ backgroundColor: 'rgb(238 238 238)', overflow: 'auto' }}
            id={item.id}
          >
            <List.Item style={{ display: 'flex', width: '100%' }} className="list-on-mobile">
              <List.Item.Meta
                className="listMeta"
                style={{ display: 'flex', width: '100%' }}
                title={(
                  <Row>
                    <Col sm={3} style={{ minWidth: 'fit-content' }}>
                      <div style={{ fontSize: '22px' }} className="margin-bottom-xs">{item.name}</div>
                      <div style={{ fontSize: '14px', opacity: '0.5' }}>{forms.title}</div>
                    </Col>
                    <Col sm={3} style={{ minWidth: 'fit-content' }}>
                      <div style={{ fontSize: '22px' }} className="margin-bottom-xs">{moment(item.startDate).format('DD, MMM YYYY')}</div>
                      <div style={{ fontSize: '14px', opacity: '0.5' }}>{coursesConst.startDate}</div>
                    </Col>
                    <Col sm={3} style={{ minWidth: 'fit-content' }}>
                      <div style={{ fontSize: '22px' }} className="margin-bottom-xs">{moment(item.endDate).format('DD, MMM YYYY')}</div>
                      <div style={{ fontSize: '14px', opacity: '0.5' }}>{coursesConst.endDate}</div>
                    </Col>
                    <Col sm={3} style={{ minWidth: 'fit-content' }}>
                      <div style={{ fontSize: '22px' }} className="margin-bottom-xs">
                        <>
                          {item?.coach?.firstName}
                          &nbsp;
                          {item?.coach?.lastName}
                        </>
                      </div>
                      <div style={{ fontSize: '14px', opacity: '0.5' }}>{user.coach}</div>
                    </Col>
                  </Row>
                )}
              />
            </List.Item>
          </div>
        )}
      />
    </Modal>
  </div>
);

const PlanCoursesModal = ({ handleClose, courses = [] }) => (
  <div>
    <Modal
      visible
      onCancel={handleClose}
      keyboard
      footer={null}
      width="60%"
      closeIcon={<CloseCircleOutlined style={{ fontSize: '26px', verticalAlign: 'center', marginTop: '1em' }} />}
      bodyStyle={{ height: 'auto', padding: '5%' }}
      className="align-center"
      closable
    >
      <h3 className="margin-bottom-lg align-left">{plans.planCourses}</h3>
      {courses?.length > 0 ? (
        <List
          itemLayout="horizontal"
          dataSource={courses}
          className="custom-list"
          renderItem={(item) => (
            <div
              className="item-wrapper align-left"
              key={item.id}
              style={{ backgroundColor: 'rgb(238 238 238)', overflow: 'auto' }}
              id={item.id}
            >
              <List.Item style={{ display: 'flex', width: '100%' }} className="list-on-mobile">
                <List.Item.Meta
                  className="listMeta"
                  style={{ display: 'flex', width: '100%' }}
                  title={(
                    <Row>
                      <Col sm={3} style={{ minWidth: 'fit-content' }}>
                        <div style={{ fontSize: '22px' }} className="margin-bottom-xs">{item.name}</div>
                        <div style={{ fontSize: '14px', opacity: '0.5' }}>{forms.title}</div>
                      </Col>
                      <Col sm={3} style={{ minWidth: 'fit-content' }}>
                        <div
                          style={{ fontSize: '22px' }}
                          className="margin-bottom-xs"
                        >
                          {moment(item.startDate).format('DD, MMM YYYY')}
                        </div>
                        <div style={{ fontSize: '14px', opacity: '0.5' }}>{coursesConst.startDate}</div>
                      </Col>
                      <Col sm={3} style={{ minWidth: 'fit-content' }}>
                        <div
                          style={{ fontSize: '22px' }}
                          className="margin-bottom-xs"
                        >
                          {moment(item.endDate).format('DD, MMM YYYY')}
                        </div>
                        <div style={{ fontSize: '14px', opacity: '0.5' }}>{coursesConst.endDate}</div>
                      </Col>
                      <Col sm={3} style={{ minWidth: 'fit-content' }}>
                        <div style={{ fontSize: '22px' }} className="margin-bottom-xs">
                          <>
                            {item?.coach?.firstName}
                            &nbsp;
                            {item?.coach?.lastName}
                          </>
                        </div>
                        <div style={{ fontSize: '14px', opacity: '0.5' }}>{user.coach}</div>
                      </Col>
                    </Row>
                  )}
                />
              </List.Item>
            </div>
          )}
        />
      ) : (
        <Col>
          <div
            className="align-center margin-top-xl margin-bottom-xl"
            style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}
          >
            <Empty description="No courses found for this plan" />
          </div>
        </Col>
      ) }
    </Modal>
  </div>
);

const AddPlanCoursesModal = ({ handleClose, plan }) => {
  const gymId = localStorage.getItem('gymId');
  const [courseIds, setCourseIds] = useState([]);
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [updateSubscriptionPlan] = useMutation(UPDATE_SUBSCRIPTION_PLAN);
  const { loading: coursesLoading, data: coursesData } = useQuery(GET_COURSES, { fetchPolicy: 'network-only', variables: { gymId } });

  useEffect(() => {
    if (!coursesLoading && coursesData?.getCourses) {
      setCourses(coursesData.getCourses);
    }
  }, [coursesLoading, coursesLoading]);

  useEffect(() => {
    const ids = (plan && plan.courses) ? plan.courses.map((course) => course.id) : [];
    setCourseIds(ids);
  }, [plan]);

  const addCourses = () => {
    setIsLoading(true);
    updateSubscriptionPlan({
      refetchQueries: [{ query: GET_SUBSCRIPTION_PLANS, variables: { gymId } }],
      variables: {
        courseIds,
        update: {
          title: plan.title,
          description: plan.description,
          billingCycle: plan.billingCycle,
          duration: plan.duration,
          price: plan.price,
          discount: plan.discount,
        },
        planId: plan.id,
      },
    })
      .then(() => {
        setIsLoading(false);
        successMessage(successfulMessage.updatePlan);
        handleClose();
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  return (
    <div>
      <Modal
        visible
        onCancel={handleClose}
        keyboard
        footer={null}
        width="60%"
        closeIcon={<CloseCircleOutlined style={{ fontSize: '26px', verticalAlign: 'center', marginTop: '1em' }} />}
        bodyStyle={{ height: '40vh', padding: '5%' }}
        // className="align-center"
        closable
      >
        <h3 className="margin-bottom-lg align-left">{plans.planCourses}</h3>
        <Form onFinish={addCourses} className="margin-xl">
          <Text style={{ color: '#767676', fontSize: '16px' }}>{coursesConst.self}</Text>
          <Form.Item
            name="coachId"
            rules={[{ required: !courseIds.length, message: getValidationMessage(coursesConst.self) }]}
            initialValue={plan?.course?.id || ''}
          >
            <div className="form-select">
              <Select
                mode="multiple"
                onChange={(val) => setCourseIds(val)}
                loading={coursesLoading}
                value={courseIds || plan?.courses}
              >
                {courses?.map((course) => (
                  <Option key={course.id} value={course.id}>
                    {course.name}
                  </Option>
                ))}
              </Select>
            </div>
          </Form.Item>
          <div className="margin-top-xl align-center">
            <MainButton float={null} loading={isLoading} fontSize="18px" htmlType="submit" text={operations.save} />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

const AlertMsgModal = ({ handleClose }) => {
  const gymId = localStorage.getItem('gymId');

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (formData) => {
    const { title, message } = formData;
    setIsLoading(true);
    createMessage({
      refetchQueries: [{ query: GET_MESSAGES, variables: { gymId } }],
      variables: {
        message: {
          title,
          message,
          type: 'ALERT',
        },
        gymId,
      },
    })
      .then(() => {
        setIsLoading(false);
        successMessage(successfulMessage.createMessageAlert);
        handleClose();
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  return (
    <div>
      <Modal
        visible
        onCancel={handleClose}
        keyboard
        footer={null}
        width={800}
        closeIcon={<CloseCircleOutlined style={{ fontSize: '26px', verticalAlign: 'center', marginTop: '1em' }} />}
        bodyStyle={{ height: 'auto', padding: '5%' }}
        className="align-center"
        closable
      >
        <h3 className="align-left margin-bottom-lg margin-left-md">{notifications.sendAlert}</h3>
        <Form className="padding-lg align-left" style={{ paddingTop: '0px' }} onFinish={onSubmit}>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.title}</Text>
          <Form.Item
            name="title"
            rules={[{ required: true, message: getValidationMessage(forms.title) }]}
          >
            <Input
              className="form-field"
            />
          </Form.Item>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.message}</Text>
          <Form.Item
            name="message"
            rules={[{ required: true, message: getValidationMessage(forms.message) }]}
          >
            <Input.TextArea
              style={{ height: '200px' }}
              className="form-field"
            />
          </Form.Item>
          <div className="text-align-last-center margin-top-xl">
            <MainButton
              loading={isLoading}
              htmlType="submit"
              fontSize="20px"
              text={operations.send}
              float="none"
              width="50px"
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

const CreateMsgModal = ({ handleClose }) => {
  const gymId = localStorage.getItem('gymId');
  const history = useHistory();
  const { loading, data } = useQuery(GET_COURSES, { fetchPolicy: 'network-only', variables: { gymId } });

  const [coursesData, setCoursesData] = useState([]);
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    if (!loading && data && data.getCourses) {
      setCoursesData(data.getCourses);
    }
  }, [loading, data]);

  const [createMessage] = useMutation(CREATE_MESSAGE);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (formData) => {
    const { title, message } = formData;
    setIsLoading(true);
    createMessage({
      refetchQueries: [{ query: GET_MESSAGES, variables: { gymId } }],
      variables: {
        courses,
        message: {
          title,
          message,
          type: 'SIMPE',
        },
        gymId,
      },
    })
      .then(() => {
        setIsLoading(false);
        successMessage('Message created successfully');
        handleClose();
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  return (
    <div>
      <Modal
        visible
        onCancel={handleClose}
        keyboard
        footer={null}
        width={800}
        closeIcon={<CloseCircleOutlined style={{ fontSize: '26px', verticalAlign: 'center', marginTop: '1em' }} />}
        bodyStyle={{ height: 'auto', padding: '5%' }}
        className="align-center"
        closable
      >
        <h3 className="align-left margin-bottom-lg margin-left-md">Create Messages</h3>
        <Form className="padding-lg align-left margin-top-sm" style={{ paddingTop: '0px' }} onFinish={onSubmit}>
          <Row>
            <Col sm={6}>
              <Text style={{ color: '#767676', fontSize: '16px' }}>Title</Text>
              <Form.Item
                name="title"
                rules={[{ required: true, message: 'Title is required' }]}
              >
                <Input
                  className="form-field"
                />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Text style={{ color: '#767676', fontSize: '16px' }}>Courses</Text>
              <Form.Item name="course">
                <Select mode="multiple" onChange={(courseArray) => setCourses(courseArray)} defaultValue={courses}>
                  {coursesData.map((course) => <Option key={course.id} value={course.id}>{course.name}</Option>)}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Text style={{ color: '#767676', fontSize: '16px' }}>Message</Text>
          <Form.Item
            name="message"
            rules={[{ required: true, message: 'Message is required' }]}
          >
            <Input.TextArea
              style={{ height: '200px' }}
              className="form-field"
            />
          </Form.Item>
          <div className="text-align-last-center margin-top-xl">
            <MainButton
              loading={isLoading}
              htmlType="submit"
              fontSize="20px"
              text="Save"
              float="none"
              width="50px"
            />
          </div>
        </Form>
      </Modal>
    </div>
  );
};

const AddInstructionsModal = ({ handleClose, type }) => {
  const gymId = localStorage.getItem('gymId');
  const history = useHistory();
  const instruction = JSON.parse(localStorage.getItem('instruction')) || {};
  const { loading, data } = useQuery(GET_CONSUMERS, { variables: { gymId } });
  const [consumers, setConsumers] = useState([]);
  const [document, setDocument] = useState(instruction?.document || '');
  const [documentError, setDocumentError] = useState('');
  const [members, setMembers] = useState(instruction?.members?.map((member) => member.id) || []);
  const [isLoading, setIsLoading] = useState(false);
  const [createInstructions] = useMutation(CREATE_INSTRUCTION);
  const [updateInstructions] = useMutation(UPDATE_INSTRUCTION);

  useEffect(() => () => localStorage.removeItem('instruction'), []);

  useEffect(() => {
    if (documentError.length && document.length) {
      setDocumentError('');
    }
  }, [document]);

  useEffect(() => {
    if (type === 'update' && !Object.keys(instruction).length) {
      history.push('/main/instructions');
    }
  }, [type, instruction]);

  useEffect(() => {
    if (!loading && data && data.getConsumers) {
      setConsumers(data.getConsumers);
    }
  }, [loading, data]);

  const onAdd = (formData) => {
    if (!document.length) {
      setDocumentError(getValidationMessage('pdf'));
    } else {
      const {
        title, startDate, endDate,
      } = formData;
      setIsLoading(true);
      createInstructions({
        refetchQueries: [{ query: GET_INSTRUCTIONS, variables: { gymId } }],
        variables: {
          members,
          instructions: {
            title,
            startDate: moment.utc(startDate),
            endDate: moment.utc(endDate),
            document,
          },
          gymId,
        },
      })
        .then(() => {
          setIsLoading(false);
          successMessage(successfulMessage.addInstruction);
          handleClose();
        })
        .catch((err) => {
          setIsLoading(false);
          errorMessage(err.toString());
        });
    }
  };

  const onUpdate = (formData) => {
    if (!document.length) {
      setDocumentError(getValidationMessage('pdf'));
    } else {
      const {
        title, startDate, endDate,
      } = formData;
      setIsLoading(true);
      updateInstructions({
        refetchQueries: [{ query: GET_INSTRUCTIONS, variables: { gymId } }],
        variables: {
          membersUpdate: members,
          instructionsUpdate: {
            title,
            startDate: moment.utc(startDate),
            endDate: moment.utc(endDate),
            document,
          },
          instructionId: instruction.id,
        },
      })
        .then(() => {
          setIsLoading(false);
          successMessage(successfulMessage.updateInstruction);
          handleClose();
        })
        .catch((err) => {
          setIsLoading(false);
          errorMessage(err.toString());
        });
    }
  };

  return (
    <div>
      <Modal
        visible
        onCancel={handleClose}
        keyboard
        footer={null}
        width={800}
        closeIcon={<CloseCircleOutlined style={{ fontSize: '26px', verticalAlign: 'center', marginTop: '1em' }} />}
        bodyStyle={{ height: '60vh', overflow: 'scroll' }}
        className="align-center"
        closable
      >
        <h3 className="align-left margin-left-lg margin-bottom-lg margin-top-xs">
          {type === 'add' ? operations.add : operations.update}
          {' '}
          {instructions.self}
        </h3>
        <Form className="padding-xl align-left" style={{ paddingTop: '0px' }} onFinish={type === 'add' ? onAdd : onUpdate}>
          <Row>
            <Col className="margin-bottom-lg">
              <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.title}</Text>
              <Form.Item
                name="title"
                initialValue={instruction.title || ''}
                rules={[{ required: true, message: getValidationMessage(forms.title) }]}
              >
                <Input className="form-field-outlined" />
              </Form.Item>
            </Col>
            <Col className="margin-bottom-lg">
              <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.file}</Text>
              <UploadComponent instructionsForm getUrl={(url) => setDocument(url)} error={documentError} />
            </Col>
          </Row>
          <Row className="margin-bottom-md">
            <Col sm={6}>
              <Text style={{ color: '#767676', fontSize: '16px' }}>{coursesConst.startDate}</Text>
              <Form.Item
                name="startDate"
                rules={[{ required: true, message: getValidationMessage(coursesConst.startDate) }]}
                initialValue={Object.keys(instruction).length > 0 ? moment(instruction.startDate) : undefined}
              >
                <DatePicker
                  className="form-field-outlined"
                  format="DD-MM-YYYY"
                  placeholder={forms.selectDate}
                  suffixIcon={(
                    <Icon
                      component={() => (
                        <img
                          src={calender}
                          alt="calender"
                          style={{ height: '20px' }}
                        />
                      )}
                    />
                  )}
                />
              </Form.Item>
            </Col>
            <Col sm={6}>
              <Text style={{ color: '#767676', fontSize: '16px' }}>{coursesConst.endDate}</Text>
              <Form.Item
                name="endDate"
                rules={[{ required: true, message: getValidationMessage(coursesConst.endDate) }]}
                initialValue={Object.keys(instruction).length > 0 ? moment(instruction.endDate) : undefined}
              >
                <DatePicker
                  className="form-field-outlined"
                  format="DD-MM-YYYY"
                  placeholder={forms.selectDate}
                  suffixIcon={(
                    <Icon
                      component={() => (
                        <img
                          src={calender}
                          alt="calender"
                          style={{ height: '20px' }}
                        />
                      )}
                    />
                  )}
                />
              </Form.Item>
            </Col>
          </Row>
          <Text style={{ color: '#767676', fontSize: '16px' }}>{forms.selectUsers}</Text>
          <Divider />
          <Row>
            <Col>
              <List
                size="large"
                dataSource={consumers}
                loading={loading}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    className="selectable hoverable"
                    onClick={() => (members.includes(item.id)
                      ? setMembers(members.filter((member) => member !== item.id))
                      : setMembers([...members, item.id])
                    )}
                    extra={(
                      <Checkbox
                        value={item.id}
                        checked={members.includes(item.id)}
                        onChange={(e) => (e.target.checked ? setMembers([...members, e.target.value])
                          : setMembers(members.filter((member) => member !== e.target.value)))}
                      />
                    )}
                  >
                    <List.Item.Meta
                      avatar={(
                        <Avatar
                          size={64}
                          src={(
                            <img
                              src={item.photo || profile}
                              style={{ objectFit: 'cover' }}
                              alt={item.name}
                            />
                          )}
                        />
                      )}
                      title={(
                        <>
                          {item.firstName}
                          &nbsp;
                          {item.lastName}
                        </>
                      )}
                    />
                  </List.Item>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col className="text-align-last-center margin-top-xl">
              <MainButton
                loading={isLoading}
                htmlType="submit"
                fontSize="20px"
                text={operations.save}
                float="none"
                width="50px"
              />
            </Col>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export {
  ParticipantInvitedModal, AddUserModal, UserCoursesModal, AlertMsgModal, CreateMsgModal, AddInstructionsModal, PlanCoursesModal, AddPlanCoursesModal,
};
