/* eslint-disable react/prop-types,no-nested-ternary,max-len */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import {
  Form, Input, Typography, Select,
} from 'antd';
import { Col, Row } from 'reactstrap';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import ContentHeader from '../components/contentHeader';
import { errorMessage, successMessage } from '../utils';
import {
  CREATE_SUBSCRIPTION_PLAN,
  GET_SUBSCRIPTION_PLANS,
  UPDATE_SUBSCRIPTION_PLAN,
} from '../graphQl/graphql';
import { MainButton } from '../components/buttons';
import {
  duration as durationConst,
  forms,
  getValidationMessage,
  operations,
  subscriptionPlans,
  successfulMessage,
} from '../assets/constants';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

function PlanForm({ type }) {
  const history = useHistory();
  const gymId = localStorage.getItem('gymId');
  const plan = JSON.parse(localStorage.getItem('plan')) || {};
  const [createSubscriptionPlan] = useMutation(CREATE_SUBSCRIPTION_PLAN);
  const [updateSubscriptionPlan] = useMutation(UPDATE_SUBSCRIPTION_PLAN);
  const [loading, setIsLoading] = useState(false);
  const [billingCycle, setBillingCycle] = useState(plan?.billingCycle || '');
  const [courses] = useState(plan?.courses || []);

  useEffect(() => () => localStorage.removeItem('plan'), []);

  useEffect(() => {
    if (type === 'update' && !Object.keys(plan).length) {
      history.push('/main/subscriptions');
    }
  }, [type, plan]);

  const addPlan = (formData) => {
    const {
      title, description, duration, price, discount,
    } = formData;
    const courseIds = courses.map((course) => course.id);
    setIsLoading(true);
    createSubscriptionPlan({
      refetchQueries: ['getSubscriptionPlans'],
      variables: {
        courseIds,
        plan: {
          title,
          description,
          billingCycle,
          duration,
          price,
          discount,
        },
        gymId,
      },
    })
      .then(() => {
        setIsLoading(false);
        successMessage(successfulMessage.addPlan);
        history.push('/main/subscriptions');
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  const updatePlan = (formData) => {
    const {
      title, description, duration, price, discount,
    } = formData;
    const courseIds = courses.map((course) => course.id);
    setIsLoading(true);
    updateSubscriptionPlan({
      refetchQueries: [{ query: GET_SUBSCRIPTION_PLANS, variables: { gymId } }],
      variables: {
        courseIds,
        update: {
          title,
          description,
          billingCycle,
          duration,
          price,
          discount,
        },
        planId: plan.id,
      },
    })
      .then(() => {
        setIsLoading(false);
        successMessage(successfulMessage.updatePlan);
        localStorage.removeItem('plan');
        history.push('/main/subscriptions');
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  return (
    <div>
      <ContentHeader
        headingName={type === 'add' ? subscriptionPlans.add : subscriptionPlans.update}
        hasButton={false}
      />
      <div>
        <Form className="margin-top-xl" onFinish={type === 'add' ? addPlan : updatePlan}>
          <Row>
            <Col>
              <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.title}</Text>
              <Form.Item
                name="title"
                rules={[{ required: true, message: getValidationMessage(forms.title) }]}
                initialValue={plan.title || ''}
              >
                <Input className="form-field-white" />
              </Form.Item>
            </Col>
            <Col>
              <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.cost}</Text>
              <Form.Item
                name="price"
                rules={[{ required: true, message: getValidationMessage(forms.cost) }]}
                normalize={(value) => value && value.replace(/[^\d.$]/, '')}
                initialValue={plan.price || ''}
              >
                <Input prefix="€" className="form-field-white" />
              </Form.Item>
            </Col>
            <Col>
              <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.discount}</Text>
              <Form.Item
                name="discount"
                rules={[{ required: true, message: getValidationMessage(forms.discount) }]}
                normalize={(value) => value && value.replace(/[^\d.$]/, '')}
                initialValue={plan.discount || ''}
              >
                <Input suffix="%" className="form-field-white" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Text style={{ color: '#767676', fontSize: '18px' }}>{durationConst.base}</Text>
              <Form.Item
                name="billingCycle"
                rules={[{ required: !billingCycle, message: getValidationMessage(durationConst.base) }]}
                initialValue={plan.billingCycle || ''}
              >
                <div className="form-select-white">
                  <Select onChange={(val) => setBillingCycle(val)} defaultValue={billingCycle}>
                    <Option value="Daily" key="monthly">{durationConst.daily}</Option>
                    <Option value="Monthly" key="monthly">{durationConst.monthly}</Option>
                    <Option value="Annually" key="monthly">{durationConst.annually}</Option>
                  </Select>
                </div>
              </Form.Item>
            </Col>
            <Col sm={4}>
              <Text style={{ color: '#767676', fontSize: '18px' }}>{durationConst.total}</Text>
              <Form.Item
                name="duration"
                rules={[{ required: true, message: getValidationMessage(durationConst.total) }]}
                initialValue={plan.duration || ''}
              >
                <Input className="form-field-white" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.description}</Text>
              <Form.Item
                name="description"
                rules={[{ required: true, message: getValidationMessage(forms.description) }]}
                initialValue={plan.description || ''}
              >
                <TextArea rows={5} />
              </Form.Item>
            </Col>
          </Row>
          <div className="margin-top-md">
            <MainButton loading={loading} fontSize="20px" htmlType="submit" text={type === 'add' ? operations.save : operations.update} />
          </div>
        </Form>
      </div>
    </div>
  );
}

export default PlanForm;
