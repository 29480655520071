// eslint-disable-next-line max-len
/* eslint-disable import/no-unresolved,no-nested-ternary,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions,max-len,jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import {
  Col, Row, Card,
} from 'reactstrap';
import {
  Divider, Empty, Spin, Menu, Dropdown,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import ContentHeader from '../components/contentHeader';
import editIcon from '../assets/Images/editIcon.svg';
import deleteIcon from '../assets/Images/deleteIcon.svg';
import { GET_SUBSCRIPTION_PLANS, DELETE_SUBSCRIPTION_PLAN } from '../graphQl/graphql';
import { errorMessage, successMessage } from '../utils';
import { PlanCoursesModal, AddPlanCoursesModal } from '../components/modals';
import {
  courses,
  duration, forms, noData, successfulMessage, subscriptionPlans as plans,
} from '../assets/constants';

function Subscriptions() {
  const { styles } = Subscriptions;
  const history = useHistory();
  const gymId = localStorage.getItem('gymId');

  const { loading, data } = useQuery(GET_SUBSCRIPTION_PLANS, { variables: { gymId } });

  const [showCoursesModal, setShowCoursesModal] = useState(false);
  const [showAddCoursesModal, setShowAddCoursesModal] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [deleteSubscriptionPlan] = useMutation(DELETE_SUBSCRIPTION_PLAN);

  const [coursesModalData, setCoursesModalData] = useState([]);
  const [planToUpdate, setPlanToUpdate] = useState(null);

  const onEdit = (item) => {
    localStorage.setItem('plan', JSON.stringify(item));
    history.push('/main/subscriptions/update-plan');
  };

  const showCourses = (plan) => {
    setCoursesModalData(plan.courses);
    setShowCoursesModal(true);
  };

  const hideCourses = () => {
    setCoursesModalData([]);
    setShowCoursesModal(false);
  };

  const addCourses = (item) => {
    setPlanToUpdate(item);
    setShowAddCoursesModal(true);
  };

  const hideAddCourses = () => {
    setPlanToUpdate(null);
    setShowAddCoursesModal(false);
  };

  const editOptions = (item) => (
    <Menu style={{ borderRadius: '6px' }}>
      <Menu.Item>
        <div onClick={() => onEdit(item)}>
          {plans.editPlan}
        </div>
      </Menu.Item>
      <Menu.Item>
        <div onClick={() => addCourses(item)}>
          {plans.editCourses}
        </div>
      </Menu.Item>
    </Menu>
  );

  const onDelete = (planId) => {
    setSubscriptionPlans(subscriptionPlans.map((plan) => (plan.id === planId ? { ...plan, loading: true } : plan)));
    deleteSubscriptionPlan({
      refetchQueries: [{ query: GET_SUBSCRIPTION_PLANS, variables: { gymId } }],
      variables: {
        planId,
      },
    })
      .then(() => {
        setSubscriptionPlans(subscriptionPlans.map((plan) => (plan.id === planId ? { ...plan, loading: false } : plan)));
        successMessage(successfulMessage.delete);
      })
      .catch((err) => {
        setSubscriptionPlans(subscriptionPlans.map((plan) => (plan.id === planId ? { ...plan, loading: false } : plan)));
        errorMessage(err.toString());
      });
  };

  useEffect(() => {
    if (!loading && data && data.getSubscriptionPlans) {
      setSubscriptionPlans(data.getSubscriptionPlans);
    }
  }, [loading, data]);

  return (
    <div>
      <ContentHeader headingName={plans.self} buttonText={plans.add} linkTo="/main/subscriptions/add-new-plan" />
      {loading
        ? <div className="margin-top-xl text-align-last-center"><Spin /></div>
        : !subscriptionPlans?.length ? (
          <Col>
            <div className="align-center margin-top-xl margin-bottom-xl" style={{ fontSize: '18px', color: 'rgba(0,0,0,0.3)' }}>
              <Empty description={noData} />
            </div>
          </Col>
        )
          : (
            <Row>
              {subscriptionPlans?.map((item) => (
                <Col sm={4} key={item.id}>
                  <Card
                    className="shadow p-3 mb-1"
                    style={{ borderRadius: '10px', width: '100%' }}
                  >
                    <Row className="float-right margin-top-sm padding-right-md" style={{ alignSelf: 'flex-end' }}>
                      <Dropdown placement="bottomRight" overlay={() => editOptions(item)}>
                        <span className="selectable" onClick={(e) => e.preventDefault()}>
                          <img
                            src={editIcon}
                            alt="edit"
                            className="margin-right-sm"
                            style={{ height: '20px' }}
                          />
                        </span>
                      </Dropdown>
                      <span
                        className="selectable"
                        onClick={() => onDelete(item.id)}
                      >
                        {
                          item.loading
                            ? <Spin className="margin-right-lg margin-left-sm margin-top-xs" />
                            : (
                              <img
                                className="margin-right-lg margin-left-sm"
                                src={deleteIcon}
                                alt="delete"
                                style={{ height: '20px' }}
                              />
                            )
                        }
                      </span>
                    </Row>
                    <Row className="margin-top-md" style={{ justifyContent: 'center' }}>
                      <div
                        className="margin-top-xl"
                        style={{
                          background: '#E2FBD7',
                          display: 'flex',
                          borderRadius: '8px',
                          width: '250px',
                          fontSize: '20px',
                          padding: '1.25em',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {item.title}
                      </div>
                    </Row>
                    <Row className="padding-lg margin-top-lg" style={{ justifyContent: 'center' }}>
                      <span>
                        <span style={{ color: '#5663F0', fontSize: '38px', fontWeight: '700' }}>
                          €
                          {item.price}
                        </span>
                        <span style={{ fontSize: '22px', fontWeight: '400' }}>
                          /&nbsp;
                          {item.billingCycle}
                        </span>
                      </span>
                    </Row>
                    <div className="padding-y-lg">
                      <Divider />
                    </div>
                    <Row className="margin-x-lg" style={{ alignSelf: 'center' }}>
                      <Col>
                        <Row style={{ justifyContent: 'center' }}>
                          <p style={styles.descStyle1}>
                            {duration.self}
                            :&nbsp;
                          </p>
                          <p style={styles.descStyle2}>{item.duration}</p>
                        </Row>
                        <Row style={{ justifyContent: 'center' }}>
                          <p style={styles.descStyle1}>
                            {forms.discount}
                            :&nbsp;
                          </p>
                          <p style={styles.descStyle2}>
                            {
                              <>
                                {item.discount}
                                %
                              </> || '-'
                            }
                          </p>
                        </Row>
                      </Col>
                    </Row>
                    <div className="padding-y-lg">
                      <Divider />
                    </div>
                    <Row className="margin-x-lg" style={{ justifyContent: 'center' }}>
                      <p style={{ ...styles.descStyle1, color: '#00000088' }}>{item.description}</p>
                    </Row>
                    <div className="padding-y-lg">
                      <Divider />
                    </div>
                    <Row className="margin-x-lg" style={{ justifyContent: 'center' }}>
                      <div
                        onClick={() => showCourses(item)}
                        className="margin-bottom-xs courses-grey selectable"
                      >
                        {item?.courses?.length || '0'}
                        {' '}
                        {courses.self}
                      </div>
                    </Row>
                  </Card>
                  <br />
                </Col>
              ))}
            </Row>
          )}
      {
        showCoursesModal && (
          <PlanCoursesModal
            handleClose={hideCourses}
            courses={coursesModalData}
          />
        )
      }
      {
        showAddCoursesModal && (
          <AddPlanCoursesModal
            handleClose={hideAddCourses}
            plan={planToUpdate}
          />
        )
      }
    </div>
  );
}

Subscriptions.styles = {
  descStyle1: {
    color: '#00000055',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
  },
  descStyle2: {
    color: 'black',
    fontWeight: '500',
    fontSize: '16px',
    lineHeight: '24px',
  },
};

export default Subscriptions;
