/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Row, Col } from 'reactstrap';
import {
  Form, Input, InputNumber, Typography,
} from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { MainButton } from '../components/buttons';
import { AuthLayout } from '../components/layout';
import UploadComponent from '../components/upload';
import { errorMessage, successMessage } from '../utils';
import { REGISTER_GYM } from '../graphQl/graphql';
import {
  auth, forms, getValidationMessage, gym, operations, successfulMessage, upload,
} from '../assets/constants';

const { Text } = Typography;

function Register() {
  const history = useHistory();
  const [registerMutation] = useMutation(REGISTER_GYM);
  const [loading, setIsLoading] = useState(false);
  const [next, setNext] = useState(false);
  const [stepOneData, setStepOneData] = useState({});
  const [logo, setLogo] = useState('');
  const [logoError, setLogoError] = useState('');

  useEffect(() => {
    if (logoError.length && logo.length) {
      setLogoError('');
    }
  }, [logo]);

  const onClickRegister = (formData) => {
    if (!next) {
      setStepOneData({
        firstName: formData.name.split(' ')[0],
        lastName: formData.name.split(' ')[1] || '',
        email: formData.email,
        password: formData.password,
      });
      setNext(true);
    } else if (!logo.length) {
      setLogoError(getValidationMessage('logo'));
    } else {
      const {
        gymName, phoneNumber, address, memberCount, description, code,
      } = formData;
      setIsLoading(true);
      registerMutation({
        variables: {
          user: stepOneData,
          gym: {
            name: gymName,
            phoneNumber,
            address,
            memberCount,
            logo,
            description,
          },
          code,
        },
      })
        .then(() => {
          setIsLoading(false);
          successMessage(successfulMessage.register);
          history.push('/login');
        })
        .catch((err) => {
          setIsLoading(false);
          errorMessage(err.toString());
        });
    }
  };

  const stepOne = (
    <>
      <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.fullName}</Text>
      <Form.Item
        name="name"
        rules={[{ required: true, message: getValidationMessage(forms.fullName) }]}
      >
        <Input className="form-field" />
      </Form.Item>
      <br />
      <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.email}</Text>
      <Form.Item
        name="email"
        rules={[{ required: true, message: getValidationMessage(forms.email) }]}
      >
        <Input
          className="form-field"
          type="email"
        />
      </Form.Item>
      <br />
      <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.password}</Text>
      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: getValidationMessage(forms.password),
          },
          {
            validator: (rule, value, callback) => ((value && value.length < 6)
              ? callback(auth.passwordValidation) : callback()),
          },
        ]}
      >
        <Input
          className="form-field password-field"
          type="password"
        />
      </Form.Item>
      <p style={{ color: 'gray' }} className="margin-top-sm">
        {auth.alreadyHaveAccount}
&nbsp;
        <Link style={{ color: '#5663F0' }} to="/login" href="/login">
          {auth.login}
          !
        </Link>
      </p>
      <br />
      <MainButton fontSize="20px" text={operations.next} htmlType="submit" />
    </>
  );

  const stepTwo = (
    <>
      <UploadComponent
        registerForm
        getUrl={(url) => setLogo(url)}
        text={upload.gymLogo}
        error={logoError}
      />
      <Row>
        <Col>
          <Text style={{ color: '#767676', fontSize: '18px' }}>{gym.name}</Text>
          <Form.Item
            name="gymName"
            rules={[{ required: true, message: getValidationMessage(gym.name) }]}
          >
            <Input className="form-field" />
          </Form.Item>
        </Col>
        <Col>
          <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.memberCount}</Text>
          <Form.Item
            name="memberCount"
            rules={[{ required: true, message: getValidationMessage(forms.memberCount) }]}
          >
            <InputNumber className="form-field-number" />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col>
          <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.contact}</Text>
          <Form.Item
            name="phoneNumber"
            rules={[{ required: true, message: getValidationMessage(forms.contact) }]}
            normalize={(value) => value && value.replace(/[^\d$]/, '')}
          >
            <Input className="form-field" />
          </Form.Item>
        </Col>
        <Col>
          <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.voucherCode}</Text>
          <Form.Item
            name="code"
            rules={[{ required: true, message: getValidationMessage(forms.voucherCode) }]}
          >
            <Input className="form-field" />
          </Form.Item>
        </Col>
      </Row>
      <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.description}</Text>
      <Form.Item
        name="description"
        rules={[{ required: true, message: getValidationMessage(forms.description) }]}
      >
        <Input className="form-field" />
      </Form.Item>
      <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.address}</Text>
      <Form.Item
        name="address"
        rules={[{ required: true, message: getValidationMessage(forms.address) }]}
      >
        <Input className="form-field" />
      </Form.Item>
      <br />
      <br />
      <MainButton loading={loading} fontSize="20px" htmlType="submit" text={operations.create} />
    </>
  );

  const form = (
    <Form className="margin-top-sm" onFinish={onClickRegister}>
      {next ? stepTwo : stepOne}
    </Form>
  );

  return (
    <AuthLayout
      form={form}
      title={next ? '' : auth.registerToContinue}
      marginTop={next ? '4%' : undefined}
    />
  );
}

export default Register;
