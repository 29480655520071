/* eslint-disable react/prop-types,max-len,no-nested-ternary,no-param-reassign,no-unused-expressions */
import React, { useState } from 'react';
import { Tooltip, Upload, Typography } from 'antd';
import { CheckCircleTwoTone } from '@ant-design/icons';
import uploadImage from '../../assets/Images/uploadImage.svg';
import uploadIcon from '../../assets/Images/plusIconCircle.svg';
import { successfulMessage, upload } from '../../assets/constants';

const { Dragger } = Upload;
const { Text } = Typography;

export default function UploadComponent(props) {
  const {
    text, getUrl, registerForm, instructionsForm, hasMultiple, getFile, initialValue, error,
  } = props;

  const [file, setFile] = useState({});
  const [resFile, setResFile] = useState(initialValue || '');

  const uploadProps = {
    name: 'file',
    multiple: false,
    action: `${process.env.REACT_APP_REST_ENDPOINT}/upload`,
    onSuccess: (res, uploadedFile) => {
      setFile(uploadedFile);
      getFile && getFile(uploadedFile);
      getUrl(res[0]);
      setResFile(res[0]);
    },
    // customRequest: (info) => handleUpload(info),
    showUploadList: false,
    listType: 'picture',
  };

  const getUploadFileList = () => (resFile.length > 0 ? (resFile.length > 15 ? (
    <Tooltip title={resFile}>
      <span>
        {resFile.substring(0, 15)}
        ...
      </span>
    </Tooltip>
  ) : <>{resFile}</>) : (file?.name?.length > 15 ? (
    <Tooltip title={file.name}>
      <span>
        {file.name.substring(0, 15)}
        ...
      </span>
    </Tooltip>
  ) : <>{file.name}</>));

  const registerFormUpload = (
    <div
      className="margin-bottom-xl"
      style={{ textAlign: '-webkit-center' }}
    >
      <div className="upload-component">
        <Dragger {...uploadProps} style={{ textAlign: '-webkit-center' }}>
          {resFile.length > 0
            ? (
              <div style={{
                border: 'dotted lightgray', padding: '0.5em', borderRadius: '10px', borderColor: 'rgba(1,1,1,0.2)', width: '160px',
              }}
              >
                <img src={resFile} style={{ height: '140px', width: '140px', borderRadius: '30px' }} alt="uploadImage" />
              </div>
            ) : <img src={uploadImage} alt="uploadImage" />}
        </Dragger>
      </div>
      {
        Object.keys(file).length > 0
        && (
          <div className="margin-bottom-md" style={{ color: 'gray', display: 'flex', justifyContent: 'center' }}>
            {getUploadFileList()}
            &nbsp;
            {successfulMessage.upload}
            &nbsp;
            <CheckCircleTwoTone style={{ marginTop: '4px' }} twoToneColor="#52c41a" />
          </div>
        )
      }
      <div
        style={{ fontSize: '21px' }}
      >
        {text}
      </div>
      {error?.length > 0 && <Text type="danger">{error}</Text>}
    </div>
  );

  const settingsFormUpload = (
    <div className="upload-field-settings">
      <Dragger {...uploadProps} style={{ textAlign: '-webkit-center' }}>
        {resFile.length > 0
          ? (
            <div style={{
              border: 'dotted lightgray',
              padding: '0.5em',
              borderRadius: '10px',
              borderColor: 'rgba(1,1,1,0.2)',
              width: '160px',
            }}
            >
              <img src={resFile} style={{ height: '140px', width: '140px', borderRadius: '30px' }} alt="uploadImage" />
            </div>
          )
          : <img src={uploadImage} alt="uploadImage" />}
      </Dragger>
      {
        (Object.keys(file).length > 0) && !hasMultiple
        && (
          <div className="margin-x-md" style={{ color: 'gray', display: 'flex', justifyContent: 'center' }}>
            {getUploadFileList()}
            &nbsp;
            {successfulMessage.upload}
            &nbsp;
            <CheckCircleTwoTone style={{ marginTop: '4px' }} twoToneColor="#52c41a" />
          </div>
        )
      }
      <div
        className="margin-x-md"
        style={{ textAlign: '-webkit-center' }}
      >
        {error?.length > 0 && <Text type="danger">{error}</Text>}
      </div>
    </div>

  );

  const instructionsFormUpload = (
    <div className="upload-field">
      <Dragger {...uploadProps}>
        <img src={uploadIcon} alt="uploadIcon" style={{ height: '30px' }} />
        <span
          className="margin-left-md"
          style={{ fontSize: '14px', fontWeight: '400', color: 'rgba(1,1,1,0.6)' }}
        >
          {upload.pdf}
        </span>
        {
          Object.keys(file).length > 0
          && (
            <div
              className="margin-top-xl"
              style={{
                fontSize: '12px', color: 'gray', display: 'flex', justifyContent: 'center',
              }}
            >
              {getUploadFileList()}
              &nbsp;
              {successfulMessage.upload}
              &nbsp;
              <CheckCircleTwoTone style={{ marginTop: '4px' }} twoToneColor="#52c41a" />
            </div>
          )
        }
      </Dragger>
      <div
        className="margin-top-sm"
        style={{ textAlign: '-webkit-center' }}
      >
        {error?.length > 0 && <Text type="danger">{error}</Text>}
      </div>
    </div>
  );

  return registerForm ? registerFormUpload : instructionsForm ? instructionsFormUpload : settingsFormUpload;
}
