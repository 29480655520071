// eslint-disable-next-line max-len
/* eslint-disable react/prop-types,jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events,no-nested-ternary,max-len,no-unused-expressions */
/* eslint-disable import/no-unresolved */
import React, { useEffect, useState } from 'react';
import { Input } from 'antd';
import { Row } from 'reactstrap';
import { useMutation, useQuery } from '@apollo/client';
import searchIcon from '../assets/Images/searchIcon.svg';
import ContentHeader from '../components/contentHeader';
import { MainButton, TransparentButton } from '../components/buttons';
import { AddUserModal, ParticipantInvitedModal } from '../components/modals';
import { UserCoursesModal } from '../components/modals/modal';
import { GET_CONSUMERS, GET_COACHES, DELETE_MEMBER } from '../graphQl/graphql';
import { usersList } from './User.utils';
import { errorMessage, successMessage } from '../utils';
import { forms, successfulMessage, user as userConst } from '../assets/constants';

function Users() {
  const gymId = localStorage.getItem('gymId');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [forEdit, setForEdit] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCoursesModal, setShowCoursesModal] = useState(false);
  const [courses, setCourses] = useState([]);
  const [selectedUserOption, setSelectedUserOption] = useState(localStorage.getItem('selectedUserOption') || 'consumer');
  const { loading: consumersLoading, data: consumersData } = useQuery(GET_CONSUMERS, { variables: { gymId } });
  const { loading: coachesLoading, data: coachesData } = useQuery(GET_COACHES, { variables: { gymId } });

  const [consumers, setConsumers] = useState([]);
  const [coaches, setCoaches] = useState([]);

  useEffect(() => {
    localStorage.setItem('selectedUserOption', selectedUserOption);
  }, [selectedUserOption]);

  useEffect(() => {
    if (!consumersLoading && consumersData && consumersData.getConsumers) {
      setConsumers(consumersData.getConsumers);
    }
    if (!coachesLoading && coachesData && coachesData.getCoaches) {
      setCoaches(coachesData.getCoaches);
    }
  }, [consumersLoading, consumersData, coachesLoading, coachesData]);

  const [deleteMember] = useMutation(DELETE_MEMBER);

  const onDeleteUser = (memberId) => {
    selectedUserOption === 'consumer'
      ? setConsumers(consumers.map((consumer) => (consumer.id === memberId ? { ...consumer, loading: true } : consumer)))
      : setCoaches(coaches.map((coach) => (coach.id === memberId ? { ...coach, loading: true } : coach)));
    deleteMember({
      refetchQueries: [{ query: selectedUserOption === 'consumer' ? GET_CONSUMERS : GET_COACHES, variables: { gymId } }],
      variables: {
        gymId,
        memberId,
      },
    })
      .then(() => {
        selectedUserOption === 'consumer'
          ? setConsumers(consumers.map((consumer) => (consumer.id === memberId ? { ...consumer, loading: false } : consumer)))
          : setCoaches(coaches.map((coach) => (coach.id === memberId ? { ...coach, loading: false } : coach)));
        successMessage(successfulMessage.delete);
      })
      .catch((err) => {
        selectedUserOption === 'consumer'
          ? setConsumers(consumers.map((consumer) => (consumer.id === memberId ? { ...consumer, loading: false } : consumer)))
          : setCoaches(coaches.map((coach) => (coach.id === memberId ? { ...coach, loading: false } : coach)));
        errorMessage(err.toString());
      });
  };

  const onEditUser = (item) => {
    localStorage.setItem('consumerToUpdate', JSON.stringify(item));
    setForEdit(true);
    setShowAddUserModal(true);
  };

  return (
    <div>
      <ContentHeader headingName={userConst.users} buttonText={userConst.addNew} onClick={() => setShowAddUserModal(true)} />
      <Row className="padding-left-lg margin-bottom-lg">
        {
          selectedUserOption === 'consumer'
            ? (
              <>
                <div className="margin-right-md">
                  <TransparentButton
                    text={userConst.consumer}
                    buttonHeight="50px"
                    buttonWidth="130px"
                  />
                </div>
                <MainButton
                  text={userConst.coach}
                  buttonHeight="50px"
                  buttonWidth="130px"
                  onClick={() => setSelectedUserOption('coach')}
                />
              </>
            )
            : (
              <>
                <div className="margin-right-md">
                  <MainButton
                    text={userConst.consumer}
                    buttonHeight="50px"
                    buttonWidth="130px"
                    onClick={() => setSelectedUserOption('consumer')}
                  />
                </div>
                <TransparentButton
                  text={userConst.coach}
                  buttonHeight="50px"
                  buttonWidth="130px"
                />
              </>
            )
        }
      </Row>
      <Row className="padding-left-lg margin-bottom-lg">
        <Input
          className="form-field-white"
          style={{ width: '45%' }}
          suffix={<img src={searchIcon} alt="search" />}
          placeholder={forms.search}
        />
      </Row>
      {
        usersList({
          loading: selectedUserOption === 'consumer' ? consumersLoading : coachesLoading,
          users: selectedUserOption === 'consumer' ? consumers : coaches,
          userType: selectedUserOption,
          setShowCoursesModal,
          handleDelete: (memberId) => onDeleteUser(memberId),
          handleEdit: (user) => onEditUser(user),
          allowEdit: selectedUserOption === 'consumer',
          setCourses,
        })
      }
      {
        showAddUserModal && (
        <AddUserModal
          handleAdd={() => {
            setShowAddUserModal(false);
            setShowSuccessModal(true);
          }}
          handleClose={() => {
            setShowAddUserModal(false);
            setForEdit(false);
          }}
          forEdit={forEdit}
        />
        )
      }
      {
        showSuccessModal && (
        <ParticipantInvitedModal
          handleClose={() => setShowSuccessModal(false)}
        />
        )
      }
      {
        showCoursesModal && (
        <UserCoursesModal
          courses={courses}
          handleClose={() => {
            setCourses([]);
            setShowCoursesModal(false);
          }}
        />
        )
      }
    </div>
  );
}

export default Users;
