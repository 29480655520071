/* eslint-disable react/prop-types */
/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import {
  Form, Input,
} from 'antd';
import Text from 'antd/es/typography/Text';
import { Link, useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { MainButton } from '../components/buttons';
import { AuthLayout } from '../components/layout';
import { LOGIN } from '../graphQl/graphql';
import { errorMessage, successMessage } from '../utils';
import {
  auth, forms, getValidationMessage, successfulMessage,
} from '../assets/constants';

function Login() {
  const history = useHistory();
  const [loginMutation] = useMutation(LOGIN);
  const [loading, setIsLoading] = useState(false);

  const onClickLogin = (formData) => {
    const { email, password } = formData;
    setIsLoading(true);
    loginMutation({
      variables: {
        credentials: {
          email,
          password,
        },
      },
    })
      .then((res) => {
        setIsLoading(false);
        if (res.data.login.user.roleId === 'GYM_OWNER') {
          localStorage.setItem(
            'user',
            JSON.stringify(res.data.login.user),
          );
          localStorage.setItem(
            'authorizationToken',
            JSON.stringify(res.data.login.token),
          );
          localStorage.setItem('isLoggedIn', 'true');
          successMessage(successfulMessage.login);
          history.push('/main');
        } else {
          errorMessage('User is unauthorised to login');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        errorMessage(err.toString());
      });
  };

  const loginForm = (
    <>
      <Form className="margin-top-xl" onFinish={onClickLogin}>
        <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.email}</Text>
        <Form.Item
          name="email"
          rules={[{ required: true, message: getValidationMessage(forms.email) }]}
        >
          <Input
            className="form-field"
            type="email"
          />
        </Form.Item>
        <br />
        <Text style={{ color: '#767676', fontSize: '18px' }}>{forms.password}</Text>
        <Form.Item
          name="password"
          rules={[{ required: true, message: getValidationMessage(forms.password) }]}
        >
          <Input
            className="form-field password-field"
            type="password"
          />
        </Form.Item>
        <Link to="/forgot-password">
          <div
            className="margin-bottom-sm margin-top-sm"
            style={{
              color: '#5663F0',
              fontSize: '18px',
              fontWeight: '400',
            }}
          >
            {auth.forgotPassword}
          </div>
        </Link>
        <p style={{ color: 'gray' }}>
          {auth.dontHaveAccount}
&nbsp;
          <Link style={{ color: '#5663F0' }} to="/register" href="/register">{auth.singUp}</Link>
        </p>
        <br />
        <br />
        <MainButton
          loading={loading}
          fontSize="20px"
          htmlType="submit"
          text={auth.login}
        />
      </Form>
    </>
  );

  return <AuthLayout form={loginForm} title={auth.loginToContinue} />;
}

export default Login;
